import React, { useState } from 'react'
 
import LoginPage2 from '../../assets/Loginpage2.png';
import FormImg from "../../assets/Form.svg"
import useStyles from '../../Styles/SignUpPageStyle/ForgotStyle';
import propuserlogo from '../../assets/Propusers_logo-White.png';
import mainImg from "../../assets/main.svg";
import { Link, useNavigate } from "react-router-dom"
import config, { axiosbaseurl } from '../../config';
import swal from 'sweetalert';
import secureLocalStorage from 'react-secure-storage';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';


const ForgotPage = () => {

  const [user_data, setUserData] = useState(null)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const handleSentOtp = async (e) => {
    e.preventDefault()
    const formData = {
      email: user_data,
      type: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(user_data) ? 1 : 2
    }

    try {
      setLoading(true)
      const response = await axiosbaseurl.post('/sendOtp', formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        }
      })

      if (response.data.success == true) {
        const temp = {
          otp: response.data.otp,
          user_id: response.data.user
        }
        secureLocalStorage.setItem('temp_data', temp)
        toast.success('Otp Sent Successfully!')
        setTimeout(() => {
          navigate(`${config.appUrl}sign-in-otp`, { state: { data: user_data } })
        }, 2000)
      } else {
        toast.error('User Not Found!');
      }
      setLoading(false)

    } catch (error) {
      console.log(error)
      toast.error('Something went wrong!');
      setLoading(false)
    }
  }

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
       
      <div>
        <div
          className='bg-image py-4 row align-items-center mx-0'
          style={{
            marginTop: "90px",
            minHeight: '100vh',
            backgroundImage: `linear-gradient(to left, transparent 20%, #374053), url(${LoginPage2})`
          }}
        >
          <div className="col-lg-5 col-md-7 ms-md-5 px-md-5">

            <div className='form-container '>
              <div className='top-form-section'>
                <img src={propuserlogo}></img>
                <h4 className='pt-5 pb-4 '>Forgot Password</h4>
              </div>


              <form onSubmit={handleSentOtp}>
                <div className="row">

                  <div className="col-12 mb-3">

                    <div className="form-floating mb-3">
                      <input type='email' name='email'
                        onChange={(e) => setUserData(e.target.value)}
                        value={user_data}
                        className={'form-control form-field'}
                        required
                        placeholder="Email"></input>

                      <label htmlFor="name">Enter Phone No/Email Id</label>
                    </div>
                  </div>

                </div>

                <div className='pb-5'>
                  <button className='btn-blue w-100' type='submit' >
                    {
                      loading ? "Verifying and sending OTP.." : "Submit"
                    }
                  </button>
                </div>

                <div className='mt-5 text-center'>
                  <p style={{ color: "white" }}>Back to
                    <Link to={`${config.appUrl}sign-in`} className='text-decoration-none text-yellow'> Login </Link></p>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
       
    </div>
  )
}

export default ForgotPage