import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({

    formImg :{
        position: "absolute",
        left: "150px",
        top:"150px"
    },

    logo : {
        position: "absolute",
        top:"60px",
        left:"100px",
        alignItems: "center",

    },

    headText: { 
      position: "absolute",
      top:"130px",
      left:"110px",
      fontSize: "24px",
      textAlign: "center",
      color:"White",
    },
    
  formText : {
    position: "absolute",
    top:"120px",
    left:"18px",
    justifyContent:"center",
    borderBlockStyle:"none",
  },

  input : {
   display : "block",
   width:"320px", 
   height:"40px",
   borderRadius: "10px",
   backgroundColor: "#f5c954",
   border:"none" 
  },

  label : {
    position: "absolute",
    fontSize: "12px",
    left:"12px",
    top:"20px",
    color:"#152140"
  },

  btn : {
    position: "absolute",
    top:"80px",  
    width:"328px", 
    height:"45px", 
    borderRadius: "10px",
    backgroundColor: "#071527",
    color:"#f5c954",
    fontSize: "15px",
    border:"none"
    
  },

  LowerText : {
    position :"absolute",
    top:"460px",
    left:"150px",
    color:"White",
    fontSize:"14px"
  }


})

export default useStyles;