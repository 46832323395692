import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../Styles/LandingPageStyles/Cities.css"
import { Link } from "react-router-dom";
import config from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Cities({ cityData,heading }) {

  return (

    <div className="bd-cites-section">

      <section >
        <div className="bd-main-service-section ">
          <div className="bd-dashed-line mr-10">
          </div>
          <div>
            <p className="bd-service-first-heading text-uppercase">{heading?.title}</p>
          </div>
          <div>
            <div className="bd-dashed-line ml-10"></div>
          </div>
        </div>
      </section>

      <section className="pb-55">
        <div>
          <div className="textCenter">
            <h2 className="bd-service-main-heading">{heading?.subtitle}</h2>
          </div>
        </div>
      </section>

      <section >
        <div className="container-fluid" style={{ maxWidth: "1290px" }}>
          <div className="row">
            {
              cityData?.slice(0, 6)?.map((city) => {
                return (
                  <div key={city.id} className="col-xl-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="city-image-box">
                      <img loading="lazy" src={city.image} alt="" className="w100"  />
                      <Link to={`${config.appUrl}propuser-locality/${city?.id}`}>
                        <div className="city_name">{city.city_name}</div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div>
            <div className="textRight">
              <div>
                <Link to={`${config.appUrl}our-neighbourcities`} className="bd-SeeAll-btn py-2 text-decoration-none"> See All <FontAwesomeIcon icon={faArrowRight} className="pl-10" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

  );
}

export default Cities;
