import { faBath, faBed, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import BuyHome from "../../assets/BuyHome.png";
import { Link } from 'react-router-dom';
import config from '../../config';

const VerticlePropertyCard = ({property}) => {
    return (
        <div>
            <div className="card bd-main-card-size " >
                <div className='position-relative'>
                    {/* /Propusers_Buy-SingleFullProperty_New */}
                    <Link to={`${config.appUrl}home-for-sale/property-details/${property.id}`}>
                        <img className="card-img-top" style={{ borderRadius: '22px' }} src={property?.image} alt="Card image cap" />
                    </Link>

                    <div className='property-badge-btn font-saira'>Listed with Propusers</div>
                </div>

                <div className="card-body">
                    <div className="bd-decription-section">
                        <p className="bd-main-rent-content font-saira">&#8377; {property.price}</p>
                        <p className="card-text bd-main-decription mb-3 font-saira fw-500">{property.address}</p>
                        <div className="bd-icon-section-rent border-top py-3">
                            <div className="bd-three-rent">

                                <div className='font-saira'>  <FontAwesomeIcon icon={faBed} /><br /> {property.bhk} Seats <span className=" middle-line">  </span></div>
                            </div>
                            <div className="bd-three-rent " >
                                <div className='font-saira'> <FontAwesomeIcon icon={faBath} /><br /> {property.bathroom} Bath <span className=" middle-line"> </span></div>
                            </div>
                            <div className="bd-three-rent">
                                <div className='font-saira'>
                                    <FontAwesomeIcon
                                        icon={faSquareCaretDown} /><br />
                                    {property.built_area} SqFt
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerticlePropertyCard