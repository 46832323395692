import React, { useContext, useEffect } from "react";

import "../../Styles/Comman.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import config, { axiosbaseurl } from "../../config";

import Select from "react-select";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import "../../assets/Css/agent_form.css";
import toast, { Toaster } from "react-hot-toast";
import { customToastOptions } from "../../Styles/popup_style";
import FormField from "./FormField";
import zIndex from "@material-ui/core/styles/zIndex";
import { AppGlobalContext } from "../../GlobalContext";

const AgentJoinForm = () => {

    const [step, setStep] = useState(1);

    const [f_name, setFname] = useState("");
    const [l_name, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("+91");
    const [primaryMarket, setPrimaryMarket] = useState("");
    const [sales, setSales] = useState("");
    const [looking, setLooking] = useState("");
    const [loading, setLoading] = useState(false);
    const [allEmails, setAllEmails] = useState([]);

    const navigate = useNavigate();

    const [primarymarketoptions, setprimarymarketoptions] = useState([]);

    const { cities_options } = useContext(AppGlobalContext)

    useEffect(() => {
        setprimarymarketoptions(
            cities_options?.map((city) => ({ value: city.id, label: city.city_name }))
        );
    }, [cities_options]);

    useEffect(() => {
        const controller = new AbortController();
        async function allPropreneurs() {
            try {
                const response = await axiosbaseurl.get(`/allPropreneurs`);
                if (response.data.success == true) {
                    const emails = response.data.data?.map((item) => item.email);
                    setAllEmails(emails);
                }
            } catch (error) {
                console.log(error);
            }
        }
        allPropreneurs();
        return () => {
            controller.abort();
        };
    }, []);

    const salesoptions = [
        { value: "less_than_1", label: "Less than ₹1 crore" },
        { value: "1_to_5", label: "₹1 crore to ₹5 crore" },
        { value: "5_to_10", label: "₹5 crore to ₹10 crore" },
        { value: "10_to_50", label: "₹10 crore to ₹50 crore" },
        { value: "50_to_100", label: "₹50 crore to ₹100 crore" },
        { value: "more_than_100", label: "More than ₹100 crore" },
    ];

    const lookingoptions = [
        { value: "Individual Agent", label: "Individual Agent" },
        { value: "With Team", label: "With Team" },
    ];

    const customStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#ffff" : "#ffff",
            borderBottom: state.isFocused ? "2px solid #f2be1a" : "1px solid gray",
            fontSize: 20,
            borderRadius: 0,
            padding: "1.2px 0px",
            fontFamily: "'Saira Semi Condensed', 'sans- serif'",
            boxShadow: "none",
            "&:hover": {
                // border: '1px solid white',
            },
        }),
    };

    // validations for first form
    const handleValidation = () => {
        const newErrors = {};

        if (!f_name) {
            newErrors.f_name = "First Name is required!";
        } else if (/\d/.test(f_name)) {
            newErrors.f_name = "Digits not allowed in First Name";
        }

        if (!l_name) {
            newErrors.l_name = "Last Name is required";
        } else if (/\d/.test(l_name)) {
            newErrors.l_name = "Digits not allowed in Last Name";
        }

        if (!email) {
            newErrors.email = "Email is required";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            newErrors.email = "Invalid email format";
        } else if (allEmails.includes(email)) {
            newErrors.email = "Email Already Registered !";
        }

        // if (!(/^[0-9]*$/).test(phone)) {
        //     newErrors.phone = "Only 0-9 digits allowed";
        // }
        // if (phone.length < 10 || phone.length > 15) {
        if (phone.length != 13) {
            newErrors.phone = "Invalid phone number";
        }

        if (!primaryMarket) {
            newErrors.primaryMarket = "Primary Market is required";
        }

        if (!sales) {
            newErrors.sales = "Approximate Sales is required";
        }

        if (!looking) {
            newErrors.looking = "Looking to is required";
        }

        return {
            isOk: Object.keys(newErrors).length === 0,
            newErrors: newErrors,
        };
    };
    // ===========================

    const handleRegisterUser = async (e) => {
        if (e) e.preventDefault();

        const validation = handleValidation();
        if (validation.isOk) {
            setLoading(true);
            try {
                const formData = {
                    fname: f_name,
                    lname: l_name,
                    email: email,
                    contact: phone,
                    looking_to_propuser: looking?.value,
                    primary_market: primaryMarket?.value,
                    monthly_income: sales?.value,
                };

                const response = await axiosbaseurl.post("/addProprenuer", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (
                    response.data.success === false &&
                    response.data.data === "email already in use"
                ) {
                    toast.error("Email Already Registered!");
                }

                if (response.data.success === true) {
                    toast.success("Propreneur Registered Successfully !!");
                    setTimeout(() => {
                        navigate(`${config.appUrl}sign-in`);
                    }, 2000);
                }

                setLoading(false);
            } catch (error) {
                console.log("Error : ", error);
                toast.error("Something went wrong!");
                setLoading(false);
            }
        } else {
            const firstError = Object.keys(validation.newErrors);
            toast.error(validation.newErrors[firstError[0]]);
        }
    };

    const handleNextStep = (field_name) => {
        const validation = handleValidation();
        const errorKeys = Object.keys(validation.newErrors);

        if (!errorKeys.some((field) => field === field_name)) {
            if (step < 7) setStep((prev) => prev + 1);
        } else {
            toast.error(validation.newErrors[field_name]);
        }
    };


    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={customToastOptions}
            />
            <div
                className="bg-image py-4 row align-items-center mx-0"
                style={{
                    marginTop: "90px",
                    backgroundColor: `#f5c954`,
                }}
            >
                <div className="text-dark agent-heading text-center mt-4 mb-5">
                    <h3 className="fs-1 fw-bolder mb-0">
                        Could Propusers be your next home?
                    </h3>
                </div>
                <div className="position-relative">
                    <div className="bars-styles">
                        <div className="bar bar1 "> </div>
                        <div className="bar bar2 "> </div>
                        <div className="bar bar3 "> </div>
                    </div>
                    <div className="agent-form-container">
                        <form onSubmit={handleRegisterUser}>
                            <div>
                                {step === 1 && (
                                    <FormField
                                        step={"01"}
                                        head1={"your"}
                                        head2={"First Name? *"}
                                        handleNextStep={handleNextStep}
                                    >
                                        <div className="my-3">
                                            <input
                                                type="text"
                                                value={f_name}
                                                name="f_name"
                                                onChange={(e) => setFname(e.target.value)}
                                                placeholder="Type your answer here..."
                                                className="agent-input-field"
                                            />
                                        </div>
                                    </FormField>
                                )}

                                {step === 2 && (
                                    <FormField
                                        step={"02"}
                                        head1={"your"}
                                        head2={"last Name? *"}
                                        handleNextStep={handleNextStep}
                                    >
                                        <div className="my-3">
                                            <input
                                                type="text"
                                                value={l_name}
                                                name="l_name"
                                                onChange={(e) => setLname(e.target.value)}
                                                placeholder="Type your answer here..."
                                                className="agent-input-field"
                                            />
                                        </div>
                                    </FormField>
                                )}

                                {step === 3 && (
                                    <FormField
                                        step={"03"}
                                        head1={"what email address"}
                                        head2={
                                            "should we use to contact you? this question is required. *"
                                        }
                                        handleNextStep={handleNextStep}
                                    >
                                        <div className="my-3">
                                            <input
                                                type="text"
                                                value={email}
                                                name="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="name@example.com"
                                                className="agent-input-field"
                                            />
                                        </div>
                                    </FormField>
                                )}

                                {step === 4 && (
                                    <FormField
                                        step={"04"}
                                        head1={"what phone number"}
                                        head2={"should we use to contact you? *"}
                                        handleNextStep={handleNextStep}
                                    >
                                        <div className="my-3">
                                            <PhoneInput
                                                defaultCountry="ua"
                                                value={phone}
                                                name="phone"
                                                className="agent-input-field "
                                                style={{ paddingTop: "8px", paddingBottom: "8px" }}
                                                onChange={(phone) => setPhone(phone)}
                                            />
                                        </div>
                                    </FormField>
                                )}

                                {step === 5 && (
                                    <FormField
                                        step={"05"}
                                        head1={"what is the primary market"}
                                        head2={"you serve? *"}
                                        handleNextStep={handleNextStep}
                                    >
                                        <div className="my-4">
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={primarymarketoptions}
                                                placeholder="Type or select an option"
                                                value={primaryMarket}
                                                name="primaryMarket"
                                                onChange={(e) => setPrimaryMarket(e)}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </FormField>
                                )}

                                {step === 6 && (
                                    <FormField
                                        step={"06"}
                                        head1={"what is your approximate sales"}
                                        head2={
                                            "volume in the last 12 months? This question is required. *"
                                        }
                                        handleNextStep={handleNextStep}
                                    >
                                        <div className="my-4">
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={salesoptions}
                                                value={sales}
                                                name="sales"
                                                onChange={(e) => setSales(e)}
                                                placeholder="Type or select an option"
                                                styles={customStyles}
                                            />
                                        </div>
                                    </FormField>
                                )}

                                {step === 7 && (
                                    <FormField
                                        step={"07"}
                                        head1={"I'm"}
                                        head2={"looking to: *"}
                                        handleRegisterUser={handleRegisterUser}
                                        loading={loading}
                                    >
                                        <div className="my-4">
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={lookingoptions}
                                                value={looking}
                                                name="looking"
                                                onChange={(e) => setLooking(e)}
                                                placeholder="Type or select an option"
                                                styles={customStyles}
                                            />
                                        </div>
                                    </FormField>
                                )}

                                <div className="mt-5 pt-4">
                                    <div className="d-flex justify-content-center">
                                        {[...Array(7)].map((_, index) => {
                                            index = index + 1;
                                            return (
                                                <button
                                                    key={index}
                                                    onClick={() => setStep(index)}
                                                    type="button"
                                                    className={`px-2 step-btn text-dark-blue 
                                                        ${step === index && "bg-yellow px-md-4 px-2"
                                                        }
                                                               mx-md-2 mx-1 border-0`}
                                                    style={{ backgroundColor: "#d8d8d8" }}
                                                >
                                                    {index}
                                                </button>
                                            );
                                        })}

                                        <div>
                                            <button disabled={loading} className="btn-blue ms-3 w-auto lh-sm py-1 rounded-pill d-flex align-items-center px-3">
                                                {loading ? "Loading.." : "Go"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="bars-styles">
                        <div className="bar bar4 "> </div>
                        <div className="bar bar5 "> </div>
                        <div className="bar bar6 "> </div>
                    </div>
                </div>

                <div className="text-dark text-center my-5">
                    <p className="mb-0 font-saira text-dark-blue">
                        Looking to apply for a employee job at Propuser?
                    </p>
                </div>
            </div>
        </>
    )
}

export default AgentJoinForm