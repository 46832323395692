import { faBath, faBed, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import BuyHome from "../../assets/BuyHome.png";
import { Link } from 'react-router-dom';
import config from '../../config';

const HorizontalPropertyCard = () => {
  return (
    <div className="card bd-main-card-size d-md-flex flex-md-row" >
    <div className='horizontal-card-img'>
      
      <Link to={`${config.appUrl}`}>
        <img className="card-img-top h-100 w-100" style={{ borderRadius: '22px' }} src={BuyHome} alt="Card image cap" />
      </Link>
    </div>

    <div className="card-body ps-1 py-2" style={{ flex: '1' }}>
      <div className="bd-decription-section pe-0">
        <p className="bd-main-rent-content font-saira">&#8377; 4,20,00,000</p>
        <p className="card-text bd-main-decription mb-3 font-saira fw-500">{'23, Indrapurma, 16th Street,  Bangaluru.'}</p>
        <div className="bd-icon-section-rent border-top py-3">
          <div className="bd-three-rent">

            <div  className='font-saira'>  <FontAwesomeIcon icon={faBed} /><br /> 2 Seats <span className=" middle-line">  </span></div>
          </div>
          <div className="bd-three-rent " >
            <div className='font-saira'> <FontAwesomeIcon icon={faBath} /><br /> 2 Bath <span className=" middle-line"> </span></div>
          </div>
          <div className="bd-three-rent">
            <div className='font-saira'>
              <FontAwesomeIcon
                icon={faSquareCaretDown} /><br />
              1200 SqFt
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HorizontalPropertyCard