import React, { useState } from "react";
import "../../assets/Css/MortgageCalculator.css";

const CalculateLoan = ({ handleCalculateSubmit, setTotalLoanAmt, setTotalYears, setSchedule, setEmi }) => {
  // default values 
  const predefinedLoanValues = [
    { label: "0", value: 0 },
    { label: "25L", value: 2500000 },
    { label: "50L", value: 5000000 },
    { label: "1Cr", value: 10000000 },
    { label: "1.5Cr", value: 15000000 },
  ];

  const predefinedDownValues = [
    { label: "0", value: 0 },
    { label: "20L", value: 2000000 },
    { label: "40L", value: 4000000 },
    { label: "60L", value: 6000000 },
    { label: "80L", value: 8000000 },
  ];

  const predefinedPeriodValues = [
    { label: "0", value: 0 },
    { label: "10Y", value: 10 },
    { label: "15Y", value: 15 },
    { label: "20Y", value: 20 },
    { label: "25Y", value: 25 },
  ];

  const predefinedInterestValues = [
    { label: "0", value: 0 },
    { label: "10%", value: 10 },
    { label: "20%", value: 20 },
    { label: "30%", value: 30 }
  ];
  // --------------------------------

  const [sliderLoanValue, setLoanSliderValue] = useState(0);
  const [sliderDownValue, setDownSliderValue] = useState(0);
  const [sliderPeriodValue, setPeriodSliderValue] = useState(0);
  const [sliderInterestValue, setInterestSliderValue] = useState(0);

  const handleReset = () => {
    setLoanSliderValue(0)
    setDownSliderValue(0)
    setPeriodSliderValue(0)
    setInterestSliderValue(0)
    setEmi(0)

    setTotalLoanAmt(0)
    setTotalYears(0)
    setSchedule([])
  }

  return (
    <div className="container-fluid pt-5 rounded-30 mortgage-calculator">
      {/* <h1>Mortgage Calculator</h1> */}
      <form onSubmit={(e) => {
        e.preventDefault()
        handleCalculateSubmit(sliderLoanValue, sliderDownValue, sliderPeriodValue, sliderInterestValue)
      }
      }>
        <div className="main-cont pb-5">
          <div className="label row px-2">
            <div className="col-md-8 position-relative">
              <label className="font-saira">
                Loan Amount
              </label>
              <input
                type="range"
                required
                min={predefinedLoanValues[0].value}
                max={predefinedLoanValues[predefinedLoanValues.length - 1].value}
                step={100000}
                value={sliderLoanValue}
                onChange={(e) => setLoanSliderValue(Number(e.target.value))}
                className="range-slider-custom w-100"
              />
              <div className="range-labels">
                {predefinedLoanValues.map((item, index) => {
                  // Calculate the percentage position based on predefined values
                  const percentage =
                    ((item.value) / (predefinedLoanValues[predefinedLoanValues.length - 1].value)) * 100;

                  return (
                    <div
                      key={index}
                      className="range-label font-saira"
                      style={{
                        left: `${percentage - 2}%`,
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>

            </div>
            <div className="col-md-4 d-flex align-items-end mb-2">
              <div className="range-value-box">
                <input type="text" onChange={(e) => setLoanSliderValue(Number(e.target.value.replaceAll(',', '')))}
                  value={sliderLoanValue.toLocaleString()} className="fs-5 fw-600 mb-0 box-value font-saira lh-sm text-start ps-4" />
                <div className="rupee-symbol">₹</div>

              </div>
            </div>
          </div>
          <div className="label row px-2">
            <div className="col-md-8 position-relative">
              <label className="font-saira">
                Down Payment
              </label>
              <input
                type="range"
                required
                min={predefinedDownValues[0].value}
                max={predefinedDownValues[predefinedDownValues.length - 1].value}
                step={50000}
                value={sliderDownValue}
                onChange={(e) => setDownSliderValue(Number(e.target.value))}
                className="range-slider-custom"
              />
              <div className="range-labels">
                {predefinedDownValues.map((item, index) => {
                  // Calculate the percentage position based on predefined values
                  const percentage =
                    ((item.value) / (predefinedDownValues[predefinedDownValues.length - 1].value)) * 100;

                  return (
                    <div
                      key={index}
                      className="range-label font-saira"
                      style={{
                        left: `${percentage - 2}%`,
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-end mb-2">
              <div className="range-value-box">
                <input type="text" onChange={(e) => setDownSliderValue(Number(e.target.value.replaceAll(',', '')))}
                  value={sliderDownValue.toLocaleString()} className="fs-5 fw-600 mb-0 box-value font-saira lh-sm text-start ps-4" />
                <div className="rupee-symbol">₹</div>
              </div>
            </div>
          </div>
          <div className="label row px-2">
            <div className="col-md-8 position-relative">
              <label className="font-saira">
                Loan Period
              </label>
              <input
                type="range"
                required
                min={predefinedPeriodValues[0].value}
                max={predefinedPeriodValues[predefinedPeriodValues.length - 1].value}
                step={1}
                value={sliderPeriodValue}
                onChange={(e) => setPeriodSliderValue(Number(e.target.value))}
                className="range-slider-custom"
              />
              <div className="range-labels">
                {predefinedPeriodValues.map((item, index) => {
                  // Calculate the percentage position based on predefined values

                  const percentage =
                    ((item.value) / (predefinedPeriodValues[predefinedPeriodValues.length - 1].value)) * 100;

                  return (
                    <div
                      key={index}
                      className="range-label font-saira"
                      style={{
                        left: `${percentage - 2}%`,
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-end mb-2">
              <div className="range-value-box">
                <input type="text" disabled onChange={(e) => setPeriodSliderValue(Number(e.target.value))}
                  value={`${sliderPeriodValue} Years`} className="fs-5 fw-600 mb-0 box-value font-saira lh-sm text-center" />
              </div>
            </div>
          </div>
          <div className="label row px-2">
            <div className="col-md-8 position-relative">
              <label className="font-saira">
                Interest Rate{" "}
              </label>
              <input
                type="range"
                required
                min={predefinedInterestValues[0].value}
                max={predefinedInterestValues[predefinedInterestValues.length - 1].value}
                step={1}
                value={sliderInterestValue}
                onChange={(e) => setInterestSliderValue(Number(e.target.value))}
                className="range-slider-custom"
              />
              <div className="range-labels">
                {predefinedInterestValues.map((item, index) => {
                  const percentage =
                    ((item.value) / (predefinedInterestValues[predefinedInterestValues.length - 1].value)) * 100;

                  return (
                    <div
                      key={index}
                      style={{
                        left: `${percentage - 1}%`,
                      }}
                      className="range-label font-saira"
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-end mb-2">
              <div className="range-value-box">
                <input type="text" disabled onChange={(e) => setInterestSliderValue(Number(e.target.value))}
                  value={`${sliderInterestValue} %`} className="fs-5 fw-600 mb-0 box-value font-saira lh-sm text-center" />
              </div>
            </div>
          </div>
        </div>
        <div className="button-group">
          <div className="w-90 pe-md-4 gap-3 d-flex ">
            <button className="btn w-100 submit font-saira fw-500"
              type="submit">
              Submit</button>
            <button type="button" className="btn w-100 reset font-saira fw-500" onClick={handleReset}>Reset</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CalculateLoan;
