import React, { useEffect, useState } from 'react'

// Assets
import "../../assets/Css/Career.css"
import config, { axiosbaseurl } from '../../config'
import CustomLoader from '../CustomLoader'
import CareerForm from './CareerForm'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import megaPhoneIcon from "../../assets/Career/megaphone_icon.png"


const AllJobPosts = () => {

    const [jobPosts, setJobPosts] = useState([]);
    const [subTitle, setSubTitle] = useState('');
    const [formheading, setformheading] = useState({});

    const [loading, setLoading] = useState(false);

    const { category_id } = useParams()
    const locationData = useLocation()

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        axiosbaseurl.get(`/careerPage`)
            .then(res => {
                if (category_id) {
                    setJobPosts(res.data.jobs?.job_postings?.filter(job => job.category_id == category_id))
                } else {
                    setJobPosts(res.data.jobs?.job_postings)
                }
                setSubTitle(res.data.jobs?.subtitle)
                setformheading(res.data?.formheading)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
            })

        return () => {
            controller.abort();
        };
    }, [])

    return (
        <div>
            {loading && <CustomLoader />}

            <div className='pt-90'>

                {/* job card */}
                <section className='pt-5 '>
                    <div className="container-box ">
                        <div className='career-text-content  text-center mb-5'>
                            <h2>
                                {
                                    locationData?.state?.category_name ? locationData?.state?.category_name :
                                        <>
                                            <span className='text-yellow fw-normal'> {'All'} </span>
                                            {'Job Posts'}
                                        </>
                                }
                            </h2>
                            <p style={{ fontWeight: 500, fontSize: '16px' }}>
                                {subTitle}
                            </p>
                        </div>
                        <div className="row mt-4">
                            {
                                jobPosts?.map(job => {
                                    return <JobCard jobData={job} />
                                })
                            }
                            {
                                jobPosts?.length === 0 &&
                                <h3 className='text-center paragraph fs-5'> <span className='text-yellow'>---</span> No Job Post Available <span className='text-yellow'>---</span></h3>
                            }
                        </div>
                    </div>
                </section>

                {/* Form */}

                <CareerForm
                    formheading={formheading}
                    postLists={jobPosts?.map(job => ({ id: job.id, title: job.title }))} />

            </div>

        </div>
    )
}

const JobCard = ({ jobData }) => {

    const navigate = useNavigate()

    return (
        <div className="col-md-6 mb-5" key={jobData.id}>
            <div className='value-card job-card'>
                <div className='bg-white post-desc-card border d-md-flex'>

                    <div className="bg-yellow yellow-icon ">
                        <img src={megaPhoneIcon} alt="megaPhoneIcon" className='img-fluid' />
                    </div>

                    <div className='ms-md-4 mt-md-0 mt-3'>
                        <h5 className='mb-0 lh-sm'>{jobData.title}</h5>
                        <div className='d-md-flex justify-content-between py-2  '>
                            <span className='text-dark-blue fw-500'>Propuser</span>
                            <div className="mt-md-0 mt-2">
                                <a href={jobData?.map_link} target='_blank' className='text-dark-blue text-decoration-none fw-500'> <i className='bi bi-geo-alt text-yellow'></i> {jobData.job_location}</a>
                            </div>
                        </div>

                        <div className='mt-4'>
                            {
                                jobData?.job_type?.split(',')?.map((item, i) => {
                                    return <button
                                        key={i}
                                        className={`job-btn me-md-3 text-capitalize me-2 ${i !== 0 && 'mt-md-0 mt-2'}`}>
                                        {item}
                                    </button>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className=' pt-3 d-flex justify-content-between align-items-center '>
                    <h6 className='lh-sm text-dark-blue fw-bold mb-0'>
                        {jobData.salary.split('/')[0]}
                        <span className='fw-normal'>/{jobData.salary.split('/')[1]}</span>  </h6>

                    <div>
                        <button onClick={() => navigate(`${config.appUrl}job-details/${jobData.slug}`)} className='job-btn me-2' style={{ backgroundColor: '#F0E4C0' }}>
                            Apply Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AllJobPosts