import React, { useEffect, useState } from "react";
import useStyles from "../../Styles/LandingPageStyles/startingPageStyles";
import SearchBar from "material-ui-search-bar";
import { useNavigate } from "react-router-dom";

import config from "../../config";
import MorePopup from "./MorePopup";

function StartingPage({ homeData }) {

  const [show, setShow] = useState(false);

  const [searchString, setSearchString] = useState("");

  const classes = useStyles();

  const [allCities, setAllCities] = useState([]);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const [search_type, setSearchType] = useState('buy')

  const navigate = useNavigate();

  useEffect(() => {

    setAllCities(homeData?.cities);
    setNeighbourhoods(homeData?.localitities);

    return () => {
      setAllCities([])
      setNeighbourhoods([])
    };
  }, [homeData]);

  // start searching property
  const handleSearch = (e) => {
    if (e)
      e.preventDefault();

    // if (search_type === 'buy') {
    //   navigate(`${config.appUrl}property/home-for-sale?search_query=${searchString}`)
    // } else {
    //   navigate(`${config.appUrl}property/home-for-rent?search_query=${searchString}`)
    // }
  };

  // start showing suggestion based on user input
  const handleStartElasticSearch = (event, startSearch = false) => {
    const search_string = event.toLowerCase()
    if (search_string.length) {
      const city_suggest = allCities.filter(city => city.city_name?.toLowerCase().includes(search_string))
      const neighbour_suggest = neighbourhoods.filter(neigh => neigh.locality_name?.toLowerCase().includes(search_string))

      const all_suggestions = [
        ...getSuggestions(city_suggest, 'city_name', search_string, 'city'),
        ...getSuggestions(neighbour_suggest, 'locality_name', search_string, 'neighbourhood')
      ]
      setSearchSuggestions(all_suggestions)
    } else {
      setSearchSuggestions([])
    }

    setSearchString(event)

    // if(startSearch){
    //   if (search_type === 'buy') {
    //     navigate(`${config.appUrl}property/home-for-sale?search_query=${event}`)
    //   } else {
    //     navigate(`${config.appUrl}property/home-for-rent?search_query=${event}`)
    //   }
    // }
  }

  // create suggestion data
  function getSuggestions(options, field_name, search_string, type) {
    return options.map(option => {
      const option_name = option[field_name].toLowerCase();
      const index = option_name.indexOf(search_string);

      const beforeHighlight = option_name.substring(0, index);
      const afterHighlight = option_name.substring(index + (search_string.length));
      return ({
        before: beforeHighlight,
        middle: search_string,
        after: afterHighlight,
        type: type
      })
    })
  }

  return (
    <>

      <div>
        <div
          className="bd-banner-home"
          style={{
            backgroundImage: `url(${homeData?.page_data?.first?.image})`,
          }}
        >
          <div className="bd-banner-section">
            <div>
              <div>
                <p className="main-head-banner">
                  <span>
                    {homeData?.page_data?.first?.title?.split(" ").slice(0, 2).join(" ")}
                  </span>{" "}
                  <strong>
                    {homeData?.page_data?.first?.title.split(" ").slice(2).join(" ")}
                  </strong>
                </p>
              </div>
              <div className="display-flex bd-buttons-sell my-4">
                <div>
                  <button className={`bd-buy-capsule border-0 ${search_type === 'buy' && 'bd-active-capsule fw-bold'}`}
                    value={'buy'} onClick={(e) => setSearchType(e.target.value)}>
                    Buy
                  </button>
                </div>
                <div className="pl-10">
                  <button className={`bd-buy-capsule border-0 ${search_type === 'rent' && 'bd-active-capsule fw-bold'}`}
                    value={'rent'} onClick={(e) => setSearchType(e.target.value)}>
                    Rent
                  </button>
                </div>
              </div>
              <div className="position-relative">
                <form onSubmit={handleSearch}>
                  <SearchBar
                    className="bd-home-search"
                    value={searchString}
                    onChange={handleStartElasticSearch}
                    style={{ borderRadius: "25px", paddingLeft: '5px' }}
                    placeholder="City, Neighboorhood, Address, Zipcode.."
                    onCancelSearch={() => setSearchString('')}
                    searchIcon={<div className={classes.searchIcon} />}
                  ></SearchBar>
                </form>
                {
                  searchString.length > 1 &&
                  <div className="suggestion-box">
                    {
                      searchSuggestions.map((suggestion, index) => {
                        return (
                          <div key={index} onClick={() => handleStartElasticSearch(suggestion.before + suggestion.middle + suggestion.after, true)}>
                            {suggestion.before}
                            <strong style={{ color: '#f0b14a' }}>{suggestion.middle}</strong>
                            {suggestion.after} -
                            <strong> {suggestion.type} </strong></div>
                        )
                      })
                    }
                    {
                      searchSuggestions.length === 0 &&
                      <div className="text-danger">No Result Found !</div>
                    }
                  </div>
                }
              </div>
              <div className="pt-10">
                <div className="display-flex align-item-center justify-content-center">
                  <div className="bd-category">Ware house</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Cold Storage</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Hospital Land</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Factory</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">School Land</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category" onClick={() => setShow(true)}>More ...</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MorePopup show={show} setShow={setShow} />
    </>
  );
}

export default StartingPage;
