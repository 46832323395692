import React, { useState } from 'react'

const ReadMore = ({ children, initialLength }) => {
    const text = children ? children : '';
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p className="bd-info-agent pt-15">
            {isReadMore ? text.slice(0, initialLength) : text}
            {
                initialLength <= text?.length ?
                    <span onClick={toggleReadMore} className="read-or-hide font-saira text-dark-blue fw-600" style={{ cursor: 'pointer' }}>
                        {isReadMore ? " Read More..." : "..Show Less"}
                    </span> : null
            }
        </p>
    );
};

export default ReadMore