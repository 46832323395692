import React, { useEffect, useState } from "react";
import "../../assets/Css/Neighbourhood.css"
import { Link, useLocation } from "react-router-dom";
import config, { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";

const AllCities = () => {
    const [allCities, setAllCities] = useState([])
    const [loading, setLoading] = useState(false)
    const [active_cities, setActiveCities] = useState(2)

    const [page, setPage] = useState(1)

    const [items_perpage, setItemsPerPage] = useState(20)

    const [headingData, setHeadingData] = useState({});


    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        axiosbaseurl.get(`/homeData`)
            .then((response) => {
                // let filter_type = 0;
                // const final_city = response.data.cities?.map((city) => {

                //     if (filter_type < 3)
                //         city.filter_type = 1

                //     if (filter_type >= 3 && filter_type < 15)
                //         city.filter_type = 2

                //     if (filter_type >= 15)
                //         city.filter_type = 3

                //     filter_type++
                //     return city
                // })
                // setCities(response.data.cities)
                setAllCities(response.data.cities?.filter(city => city.city_status == '1'))
                setHeadingData(response.data.titles[3])
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })

        return () => {
            controller.abort();
        };
    }, [])

    // pagination logic
    const total_pages = Math.ceil(allCities?.length / items_perpage);

    const final_data = allCities?.slice(page * items_perpage - items_perpage, page * items_perpage)

    const changePage = (pagecount) => {
        if (pagecount > 0 && pagecount <= total_pages) {
            setPage(pagecount)
            window.scrollTo({top: 0, behavior: 'instant'})
        }
    }

    return (
        <>
            {loading && <CustomLoader />}

            <div className='pt-90'>
                <div className="bd-cites-section">
                    <section >
                        <div>
                            <div>
                                <div>
                                    <div className="bd-main-service-section ">
                                        <div className="bd-dashed-line mr-10">
                                        </div>
                                        <div>
                                            <p className="bd-service-first-heading"> {headingData?.title}</p>
                                        </div>
                                        <div>
                                            <div className="bd-dashed-line ml-10"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div>
                            <div className="textCenter mx-3">
                                <h2 className="bd-service-main-heading">{headingData?.subtitle}</h2>
                            </div>
                            <div className="my-4 py-2">
                                <div className="d-flex w-100 flex-md-row flex-column justify-content-center align-items-center home-cities">

                                    <div className="px-4 text-center top-city-tab">
                                        <h5 className={`position-relative my-md-0 my-3 ${active_cities == 1 && 'active-city-tab'}`}
                                            onClick={() => setActiveCities(1)}>Rentals House</h5>
                                    </div>

                                    <div className="px-4 text-center top-city-tab">
                                        <h5 className={`position-relative my-md-0 my-3 ${active_cities == 2 && 'active-city-tab'}`}
                                            onClick={() => setActiveCities(2)}>Real Estate In Cities</h5>
                                    </div>

                                    <div className="px-4 text-center top-city-tab">
                                        <h5 className={`position-relative my-md-0 my-3 ${active_cities == 3 && 'active-city-tab'}`}
                                            onClick={() => setActiveCities(3)}>Affordable House</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    {/* .filter(item => item.filter_type == active_cities) */}
                    <section >
                        <div>
                            <div className="container-box bd-responsive-cities" style={{ maxWidth: "1290px" }}>
                                <div className="row justify-content-md-start justify-content-center">
                                    {final_data?.map((city) => (
                                        <div key={city.id} className="col-md-3 col-sm-6 col-11 mt-4">
                                            <div className="city-image-box top-city-box">
                                                <img className="w100" src={city.image} alt={city.city_name} />
                                                <Link to={`${config.appUrl}propuser-locality/${city.id}`}>
                                                    <div className="city_name">{city.city_name}</div>
                                                </Link>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>


                            {/* Pagination buttons */}
                            <div className='d-flex justify-content-center flex-wrap my-5'>

                                <div className={`pagination-btn rounded-2 me-3 mt-2 
                                ${page === 1 && 'opacity-50'}`}
                                    onClick={() => changePage(page - 1)}>
                                    <i className='bi bi-arrow-left'></i>
                                </div>

                                {
                                    [...Array(total_pages)].map((_, pagecount) => {
                                        pagecount += 1
                                        return (
                                            <div key={pagecount} className={`pagination-btn text-center rounded-2 mx-1 mt-2 ${page === pagecount && 'activepage'}`} onClick={() => changePage(pagecount)}>
                                                {pagecount}
                                            </div>
                                        )
                                    })
                                }

                                <div className={`pagination-btn rounded-2 ms-3 mt-2 ${(page === total_pages) && 'opacity-50'}`}
                                    onClick={() => changePage(page + 1)}>
                                    <i className='bi bi-arrow-right'></i>
                                </div>

                            </div>
                        </div>
                    </section>


                </div>

            </div>


        </>
    )

}
export default AllCities