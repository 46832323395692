import React, { useEffect, useState } from 'react'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';

import icon1 from "../../../assets/footer/property_pointer.png"
import { faBath, faBed, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import config from '../../../config';

// 20.5937, 78.9629
const position = [28.5961, 77.3723];

const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [50, 50],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});


const countryStyle = {
    color: 'black',
    opacity: 1,
    height: '100%',
    width: '100%'
};


// map component 
const PropertyMapView = ({
    propertyData, property_type
}) => {

    const [propertyLocations, setPropertyLocations] = useState([])

    useEffect(() => {
        if (propertyData) {
            const allow_data = propertyData?.filter(item => (item.lat && item.lang))

            const data_for_map = allow_data?.map(property => {
                return ({
                    latitude: property.lat,
                    longitude: property.lang,
                    properties: allow_data
                        ?.filter(item => item.neighbourhood === property.neighbourhood)
                        ?.map(sub_property => {
                            return ({
                                id: sub_property?.id ,
                                price: sub_property?.price,
                                address: sub_property?.address,
                                beds: sub_property?.bhk,
                                baths: sub_property?.bathroom,
                                size: sub_property?.built_area,
                                image: sub_property?.image
                            })
                        })
                })
            })

            setPropertyLocations(data_for_map)
        }
    }, [propertyData])



    return (
        <>
            <div id='property_map' className='w-100 h-100'>
                <MapContainer
                    center={position}
                    zoom={14}
                    zoomControl={true}
                    scrollWheelZoom={false}
                    doubleClickZoom={true}
                    touchZoom={true}
                    boxZoom={false}
                    style={countryStyle}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {propertyLocations && propertyLocations?.map((location, index) => (
                        <Marker key={index}
                            position={[location.latitude, location.longitude]}
                            icon={customMarkerIcon}
                        >
                            <Popup
                                autoPan={false}
                                closeButton={false}
                                offset={[125, 5]}
                            >
                                <div className='map-property-popup-box'>
                                    {
                                        location.properties.map((property, index) => {
                                            return <PopUpBox property={property} key={index} property_type={property_type} />
                                        })
                                    }
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
        </>
    )
}

export default PropertyMapView

const PopUpBox = ({ property, property_type }) => {
    
    return (
        <Link to={`${config.appUrl}home-for-${property_type}/property-details/${property?.id}`} className='text-decoration-none'>
            <div className="bg-white map-property-popup mb-3">
                <div>
                    <img src={property.image} alt="" />
                </div>
                <div className='popup-content ms-3'>
                    <h6 className='font-saira text-dark-blue fw-bold mb-1'> ₹ {property.price}</h6>
                    <p className='font-saira mb-1 mt-0 text-dark-blue fw-500'> {property.address} </p>
                    {/* <hr className='my-1' /> */}
                    <div className="bd-icon-section-rent border-top py-1">
                        <div className="bd-three-rent">
                            <div className='font-saira fw-500'>  <FontAwesomeIcon icon={faBed} /> {property?.beds} <br /> Beds <span className="middle-line">  </span></div>
                        </div>
                        <div className="bd-three-rent " >
                            <div className='font-saira fw-500'> <FontAwesomeIcon icon={faBath} /> {property?.baths} <br /> Bath <span className="middle-line"> </span></div>
                        </div>
                        <div className="bd-three-rent">
                            <div className='font-saira fw-500'>
                                <FontAwesomeIcon
                                    icon={faSquareCaretDown} />
                                {property?.size} <br /> SqFt
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}