import React, { useEffect, useState } from 'react'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';

import icon1 from "../../assets/footer/pointer1.png"

const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [20, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const LocalityMapView = ({ localityData }) => {

    const [position, setPosition] = useState([])
    const [locations, setLocation] = useState([])
    // const position = [23, 80.962883];

    useEffect(() => {
        if (localityData) {
            const coords = localityData?.coordinates?.split(',')?.length == 2 ?
                localityData?.coordinates?.split(',') :
                ['28.5961', '77.3723']

            const latitude = Number(coords[0]?.match(/[\d.]+/)[0])
            const longitude = Number(coords[1]?.match(/[\d.]+/)[0])

            setPosition([latitude, longitude])
            setLocation([{
                name: localityData?.locality_name,
                latitude: latitude,
                longitude: longitude,
                state: localityData?.state
            }])
        }
    }, [localityData])

    return (
        <>
            <div className='w-100 h-100 locality_map'>
                {
                    position?.length > 0 &&
                    <MapContainer
                        center={position}
                        zoom={14}
                        style={{ height: '100%', width: '100%' }}
                        zoomControl={true}
                        scrollWheelZoom={false}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />

                        {locations?.map((location, index) => (
                            <Marker key={index}
                                position={[location.latitude, location.longitude]}
                                icon={customMarkerIcon}
                            >
                                <Popup
                                    autoPan={false}
                                    closeButton={false}
                                    offset={[90, 75]}
                                >
                                    <div className="">
                                        <div>
                                            <h5 className='font-saira text-dark-blue'>{location.name}</h5>
                                            <p className="my-1 font-saira fw-bold ">
                                                {location?.state}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div>
                                    <h3>{location.name}</h3>
                                    <p>{location.address}</p>
                                    <p>Contact: {location.contact}</p>
                                </div> */}
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                }
            </div>
        </>
    )
}

export default LocalityMapView