import React, { useEffect } from 'react'
 
import LoginPage2 from '../../assets/Loginpage2.png';
import { useLocation, useNavigate } from "react-router-dom"
import propuserlogo from '../../assets/Propusers_logo-White.png';
import config from '../../config';

const PassChange = () => {

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!location?.state?.data) {
      navigate(`${config.appUrl}sign-in`)
    }
  }, [])

  return (
    <div>
       

      <div
        className='bg-image py-4 row align-items-center mx-0'
        style={{
          marginTop: "90px",
          minHeight: '110vh',
          backgroundImage: `linear-gradient(to left, transparent 20%, #374053), url(${LoginPage2})`
        }}
      >
        <div className="col-lg-5 col-md-7 ms-md-5 px-md-5">

          <div className='form-container '>
            <div className='top-form-section'>
              <img src={propuserlogo}></img>
              <h4 className='my-4 pt-3 '>Password Changed</h4>
            </div>

            <div className='top-form-section mb-5'>
              <div>
                <i className='bi bi-patch-check-fill display-2' style={{ color: '#39b54a' }}></i>
              </div>
              <h3 className='fs-2 fw-bold text-white'>Thank You</h3>
              <p className='text-white mb-1'> Your Password has been Updated Successfully</p>
              <p className='text-white mb-1'>A Confirmation email has been sent to</p>
              <p className='text-white mb-1'><strong>{location?.state?.data}</strong></p>
            </div>

            <div className='pb-4'>
              <button className='btn-blue w-100' onClick={() => navigate(`${config.appUrl}login`)}>
                {
                  "Okay"
                }
              </button>
            </div>

          </div>
        </div>
      </div>

      {/* <div style={{ paddingTop: "90px" }} >
        <span>
          <img src={LoginPage2} width="100%" ></img>
          <img src={mainImg} style={{ position: "absolute", left: "0px", width: "100%" }}></img>
        </span>
        <span className={classes.formImg}>
          <img src={FormImg} width="420px"></img>
          <div className={classes.logo}>
            <img src={propuserlogo} width="180px" ></img>
          </div>
          <p className={classes.headText}> Password Changed</p>
          <p className={classes.text}><b>Thank You !</b></p>
          <p className={classes.contentful}> Your Password has been Updated Successfully</p>
          <p className={classes.contentful1}>A Confirmation email has been sent to</p>
          <p className={classes.contentful2}><strong>maj_yadav@gmail.com</strong></p>
          <Link to="/propuser/AddAccount" style={{ textDecoration: "none", color: "#F2BE1A" }}>
            <button className={classes.submit}><b>Submit</b> </button></Link>
        </span>
      </div> */}
       
    </div>
  )
}

export default PassChange