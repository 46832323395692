import React, { useState } from 'react'

import propuserlogo from '../../assets/Propusers_logo-White.png';
import '../../Styles/Comman.css';
import { Link, useNavigate } from "react-router-dom"
import config from '../../config';
import HomeImg from '../../assets/AccountImg1.png';

const CreateAccountFirst = () => {

    const [registerType, setRegisterType] = useState('')

    const handleLoginUser = async (e) => {
        e.preventDefault()
        navigate(`${config.appUrl}register`, {
            state: {
                registerType
            }
        })
    }

    const navigate = useNavigate();

    return (
        <div>
            <div
                className='bg-image py-4 row align-items-center justify-content-end mx-0'
                style={{
                    marginTop: "90px",
                    minHeight: '110vh',
                    backgroundImage: `linear-gradient(to right, transparent 20%, #374053), url(${HomeImg})`
                }}
            >
                <div className="col-xl-5 col-lg-6 col-md-8 ms-md-5 px-md-5">

                    <div className='form-container py-5'>
                        <div className='top-form-section'>
                            <img src={propuserlogo}></img>
                            <h4 className='py-4 pt-5'>Create New Account</h4>
                        </div>

                        <form onSubmit={handleLoginUser}>
                            <div className="row">

                                <div className="col-12 mb-3">

                                    <div className='yellow-box mb-3 pe-3'>
                                        <div>
                                            <i className='fa fa-user-secret fs-1 lh-sm text-dark-blue'></i>
                                        </div>
                                        <div className='ms-2 text-dark-blue' style={{ flex: 1 }}>
                                            <select
                                                value={registerType}
                                                onChange={(e) => setRegisterType(e.target.value)}
                                                required
                                                style={{ boxShadow: 'none', cursor: 'pointer' }}
                                                className='form-select border-0 bg-yellow fw-bold text-dark-blue '>
                                                <option value="">Choose Buyer/Seller</option>
                                                <option value="Buyer">Buyer</option>
                                                <option value="Seller">Seller</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div >
                                <button className='btn-blue w-100' type='submit' >
                                    Continue
                                </button>
                            </div>

                            <div className='d-flex justify-content-around mt-5 mb-4  fs-1'>
                                <a href="#"><i className="fa fa-google color-google"></i></a>
                                <a href="#"><i className="fa fa-facebook color-facebook"></i></a>
                                <a href="#"><i className="fa fa-linkedin color-linkedin"></i></a>
                                <a href="#"><i className="fa fa-apple color-apple"></i></a>
                            </div>

                            <div className='pt-3 text-center'>
                                <p style={{ color: "white" }}>Already registered?
                                    <Link to={`${config.appUrl}login`}
                                        className='text-decoration-none text-yellow'> Login</Link></p>
                            </div>

                        </form>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateAccountFirst