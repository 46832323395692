import React, { useEffect, useState } from "react";
import StartingPage from "./StartingPage";
import MakeOver from "./MakeOver";
import Services from "../LandingPage/Services";
import Cities from "./Cities";
import Tesimonials from "./Tesimonials";
import SearchingCities from "./SearchingCities";
import "../../Styles/Responsive/HomeResponsive.css";
import { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";

function LandingPage() {
  const [homeData, setHomeData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get(`/homeData`)
      .then((response) => {
        setHomeData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setHomeData(null)
      controller.abort();
    };
  }, []);

  const filterTitle = (type) => {
    const data = homeData?.titles?.filter(item => item.section?.toLowerCase()?.includes(type?.toLowerCase()))
    return data ? data : [""]
  }

  return (
    <>
      {loading && <CustomLoader />}

      <StartingPage
        homeData={homeData}
        startData={homeData?.page_data?.first}
      />
      <MakeOver makeOverData={homeData?.page_data?.second} keyFeatures={homeData?.page_data?.fourth} />

      <Services serviceData={homeData?.page_data?.third} heading={filterTitle("Property_type")[0]} />

      <Cities
        heading={filterTitle("Neighbourhood")[0]}
        cityData={homeData?.cities?.filter(city => (city.show_on_locality == 1 && city.city_status == 1))} />

      <Tesimonials
        heading={filterTitle("Testimonial")[0]}
        testimonialData={homeData?.testimonials?.filter(item => item.status == 1)} />

      <SearchingCities
        heading={filterTitle("Cities")[0]}
        topCityData={homeData?.cities?.filter(city => (city.show_on_page == 1 && city.city_status == 1))}
        ReraData={homeData?.states_with_rera?.map((rera) => ({ ...rera, rera_no: rera.rera_no ? rera.rera_no : 'NA' }))}
      />
    </>
  );
}

export default LandingPage;
