import React from 'react'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';

import icon1 from "../../../assets/footer/property_pointer.png"

// 20.5937, 78.9629
const position = [28.5961, 77.3723];

const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [20, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const countryStyle = {
    color: 'black',
    opacity: 1,
    height: '100%',
    width: '100%'
};


// map component 
const PropertyDetailMap = () => {

    // const locations = [{
    //     name: "Noida Expressway",
    //     latitude: 28.5961,
    //     longitude: 77.3723,
    //     state: "Gujarat"
    // }]

    return (
        <>
            <div className='w-100 h-100 locality_map'>
                <MapContainer
                    center={position}
                    zoom={14}
                    zoomControl={true}
                    scrollWheelZoom={false}
                    doubleClickZoom={true}
                    touchZoom={true}
                    boxZoom={false}
                    style={countryStyle}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    
                </MapContainer>
            </div>
        </>
    )
}

export default PropertyDetailMap

