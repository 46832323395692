import React, { useEffect, useRef, useState } from 'react'

import propuserlogo from '../../assets/Propusers_logo-White.png';
import LoginPage2 from '../../assets/Loginpage2.png';
import { useLocation, useNavigate } from "react-router-dom"
import config, { axiosbaseurl } from '../../config';
import secureLocalStorage from 'react-secure-storage';
import { customToastOptions } from '../../Styles/popup_style';
import toast, { Toaster } from 'react-hot-toast';
import CustomLoader from '../CustomLoader';

const LoginOtp = () => {

  const navigate = useNavigate()

  const location = useLocation()

  const user_info = secureLocalStorage.getItem("temp_data")
  const length = 6

  const initialOtp = user_info ? (String(user_info?.otp)?.split("")) : (Array(length).fill(''))
  const [otpValues, setOtpValues] = useState(initialOtp);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    setOtpValues(String(user_info?.otp)?.split(""))
  }, [user_info])

  useEffect(() => {
    if (!location?.state?.data) {
      navigate(`${config.appUrl}login`)
    }
  }, [])

  const [seconds, setSeconds] = useState(60);
  const [loading, setLoading] = useState(false);

  // 60 sec functionality
  useEffect(() => {
    if (seconds === 0) {
      return; // Stop the countdown if seconds reach 0
    }

    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component unmounts or when seconds reach 0
    return () => clearInterval(interval);
  }, [seconds]);


  // Resend Otp
  const handleReSendOtp = async () => {
    // e.preventDefault()
    const formData = {
      email: location?.state?.data,
      type: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(location?.state?.data) ? 1 : 2
    }

    try {
      setLoading(true)
      const response = await axiosbaseurl.post('/sendOtp', formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        }
      })

      if (response.data.success == true) {
        const temp = {
          otp: response.data.otp,
          user_id: response.data.user
        }
        secureLocalStorage.setItem('temp_data', temp)
        toast.success('Otp Resent Successfully!')
        setSeconds(60)
      } else {
        toast.error('User Not Found!');
      }
      setLoading(false)

    } catch (error) {
      console.log(error)
      toast.error('Something went wrong!');
      setLoading(false)
    }
  }
  // ======================

  const handleCompareOtp = async (e) => {
    e.preventDefault()

    // user_info.otp
    if (otpValues.join('') == user_info.otp) {
      toast.success('OTP Verified Successfully!')

      setTimeout(() => {
        navigate(`${config.appUrl}sign-in`, { state: { data: location?.state?.data } })
      }, 1000);

    } else {
      toast.error('Invalid OTP!');
    }

  }

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    const newValues = [...otpValues];
    newValues[index] = value;
    setOtpValues(newValues);

    // Move to the next input when a digit is entered
    if (value !== '' && index < length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleBackspace = (index, event) => {
    if (event.keyCode === 8 && index > 0 && otpValues[index] === '') {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div>
      {loading && <CustomLoader />}

      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

      <div >
        <div
          className='bg-image py-4 row align-items-center mx-0'
          style={{
            marginTop: "90px",
            minHeight: '100vh',
            backgroundImage: `linear-gradient(to left, transparent 20%, #374053), url(${LoginPage2})`
          }}
        >
          <div className="col-lg-5 col-md-7 ms-md-5 px-md-5">

            <div className='form-container '>
              <div className='top-form-section mb-5'>
                <img src={propuserlogo}></img>
                <h4 className='pt-5 fw-bold'>Verify</h4>
                <div className='text-white'>
                  <p className=' my-2'>Enter {length} Digit Verification code sent on</p>
                  <b>{location?.state?.data}</b>
                </div>
              </div>


              <form onSubmit={handleCompareOtp}>
                <div className="row">

                  <div className="col-12 mb-3">

                    <div className='form-field'>
                      <label htmlFor="">Enter {length} digits OTP</label>
                      <div className="row mt-1">

                        {otpValues.map((value, index) => (
                          <div className="col px-1" key={index}>
                            <input
                              key={index}
                              ref={inputRefs[index]}
                              type="text"
                              className='fs-6 form-field bg-white px-1 text-center text-dark py-2 shadow-sm'
                              maxLength={1}
                              value={value}
                              onChange={(e) => handleInputChange(index, e)}
                              onKeyDown={(e) => handleBackspace(index, e)}
                              required
                            />
                          </div>
                        ))}

                      </div>
                    </div>

                    <div className='top-form-section d-flex justify-content-between align-items-start text-white mt-2'>
                      <p>Valid upto {seconds} Sec</p>

                      <button onClick={handleReSendOtp}
                        className='text-yellow bg-transparent py-0 border-0 fs-6 opacity-4'
                        disabled={seconds != 0}
                        type='button'>
                        Resend
                      </button>
                    </div>
                  </div>

                </div>

                <div className='pb-5'>
                  <button className='btn-blue w-100' type='submit' >
                    {
                      "Next"
                    }
                  </button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default LoginOtp