import React, { useState, useRef, useEffect } from "react";
import propsuserlogo from "../../assets/Propusers_logo-White.png";
import customsvg from "../../assets/Appmarket.png";
import loginsvg from "../../assets/Login.png";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import "../../Styles/Header.css";
import "../../Styles/Bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import config, { axiosbaseurl } from "../../config";
import "../../Styles/Responsive/HomeResponsive.css";
import featureicon4 from "../../assets/Key Features Icons/fixed-propuser.png";
import FooterSideBar from "./FooterSideBar";
import secureLocalStorage from "react-secure-storage";

import stringSimilarity from "string-similarity";

function NavBar({ isSearchBar = true, isTransparent = false }) {
  const [userDropdown, setUserDropdown] = useState(false);
  const [proprenurDropdown, setProprenurDropdown] = useState(false);
  const [loggedInDropdown, setLoggedInDropdown] = useState(false);
  const [searchString, setSearchString] = useState("");

  const [isShown, setIsShown] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [floatingIcon, setFloatingIcon] = useState(false);

  const [isOpen, setIsopen] = useState(false);

  const url_location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const login_credential = secureLocalStorage.getItem("credentials");

  // just navigating
  useEffect(() => {
    if (url_location?.pathname.slice(-1) == "/")
      navigate(url_location?.pathname.slice(0, -1));
  }, []);

  // getting all data for search feature
  useEffect(() => {
    const controller = new AbortController();
    axiosbaseurl
      .get(`/homeData`)
      .then((response) => {
        setAllCities(response.data.cities);
        setNeighbourhoods(response.data.localitities);
      })
      .catch((error) => {
        console.log(error);
      })

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (isShown)
      setIsShown(false);
    if (userDropdown)
      setUserDropdown(false)
    if (isOpen)
      setIsopen(false)
    if (proprenurDropdown)
      setProprenurDropdown(false)
  }, [url_location.pathname])

  // for propreneur dropdown
  const handleClick = () => {
    setIsShown((current) => !current);
    setUserDropdown(false);
  };

  const ToggleSidebar = () => {
    setIsopen(!isOpen)
  };

  // for logout
  const handleLogout = () => {
    secureLocalStorage.removeItem("credentials");
    navigate(config.appUrl);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchString) {
      const trimmedSearchString = searchString.trim().toLowerCase();
      const isExactMatchCity = allCities.find(
        (city) => city.city_name.toLowerCase() === trimmedSearchString
      );
      const isExactMatchNeigh = neighbourhoods.find(
        (neigh) => neigh.locality_name.toLowerCase() === trimmedSearchString
      );
      if (!isExactMatchCity && !isExactMatchNeigh) {
        const cityMatches = stringSimilarity.findBestMatch(
          trimmedSearchString,
          allCities.map((city) => city.city_name.toLowerCase())
        );
        const neighbourhoodMatches = stringSimilarity.findBestMatch(
          trimmedSearchString,
          neighbourhoods.map((neigh) => neigh.locality_name.toLowerCase())
        );
        const similarityThreshold = 0.7;
        const bestCityMatch =
          cityMatches.bestMatch.rating >= similarityThreshold
            ? allCities[cityMatches.bestMatchIndex]
            : null;
        const bestNeighMatch =
          neighbourhoodMatches.bestMatch.rating >= similarityThreshold
            ? neighbourhoods[neighbourhoodMatches.bestMatchIndex]
            : null;

        if (bestCityMatch) {

          navigate(`${config.appUrl}propuser-locality/${bestCityMatch.id}`);
        } else if (bestNeighMatch) {
          navigate(
            `${config.appUrl}propuser-locality-detail/${bestNeighMatch.id}`
          );
        } else {
          navigate(`${config.appUrl}coming-soon`, {
            state: { data: searchString },
          });
        }
      } else {
        if (isExactMatchCity) {
          navigate(`${config.appUrl}propuser-locality/${isExactMatchCity.id}`);
        } else if (isExactMatchNeigh) {
          navigate(
            `${config.appUrl}propuser-locality-detail/${isExactMatchNeigh.id}`
          );
        }
      }
    }
  };

  const fontStyle =
    url_location?.pathname == config.appUrl ? "fs-6" : "font-saira";

  const { property_id } = useParams()

  const pagesIncludesFloatBtn = [config.appUrl, `${config.appUrl}home-for-sale/property-details/${property_id}`]

  // const userImage = `https://demo.propusers.com/admin/public/images/staff/${login_credential?.image}`
  const userImage = `${login_credential?.image}`

  return (
    <div>
      <section>
        <nav
          className={`navbar align-items-center fixed-top navbar-expand-lg navbar-light
           bg-grey${isTransparent ? "-transparent" : ""}`}
          style={{ paddingBottom: "15.5px" }}
        >
          <Link
            className="navbar-brand flex-size-header2 bd-responsive-nav2"
            to={config.appUrl}
          // style={{ marginRight: "40px" }}
          >
            <img className="main-logo" src={propsuserlogo} alt="logoIcon" />
          </Link>

          <div className="d-flex pt-3">

            {login_credential && (
              <div className="profile-img d-md-none">
                <img
                  src={userImage}
                  alt="usericon"
                  className="rounded-circle bg-yellow"
                  style={{ width: "35px", height: "35px", padding: '1px' }}
                />

                {/* <div>
                {login_credential?.name.split(' ')[0]}
              </div> */}
              </div>
            )}

            <button
              className="navbar-toggler toggle-btn bd-side-bars ms-3"
              onClick={ToggleSidebar}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>

          {isSearchBar && (
            <div className="bd-search-navbar prop-responsive d-md-block d-none">
              <div
                className="input-group main-searh-input"
              >
                <input
                  type="text"
                  className="form-control font-saira ps-3"
                  placeholder="City, Neighboorhood, Address, School, Zip, Agent, ID"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  style={{
                    fontSize: "12px",
                    border: "none",
                    color: "rgba(108, 117, 125,0.6)",
                  }}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary "
                    type="button"
                    onClick={handleSearch}
                    style={{
                      paddingTop: "2px",
                      backgroundColor: "#f5c954",
                      color: "white",
                      border: "none",
                      borderBottomLeftRadius: "0px",
                      borderTopLeftRadius: "0px",
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* desktop menu */}
          <div
            className="collapse navbar-collapse  "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav justify-content-end ml-auto w100 pt-20 pe-4">
              <li className="nav-item  bottom-line">
                <Link
                  className={`nav-link text-white ${fontStyle}`}
                  to={`${config.appUrl}`}
                >
                  {/* property/home-for-sale */}
                  {" "}
                  Buy{" "}
                </Link>
              </li>
              <li className="nav-item nav-responsive mr-5">
                <span className="nav-link side" style={{ color: "#D5A117" }}>
                  {" "}
                  |{" "}
                </span>
              </li>
              <li className="nav-item mr-5  bottom-line">
                <NavLink
                  className={`nav-link text-white ${fontStyle}`}
                  to={`${config.appUrl}propreneur-experience`}
                >
                  Sell
                </NavLink>
              </li>
              <li className="nav-item nav-responsive mr-5">
                <span className="nav-link side" style={{ color: "#D5A117" }}>
                  {" "}
                  |{" "}
                </span>
              </li>
              <li className="nav-item mr-5 bottom-line">
                <Link
                  className={`nav-link text-white ${fontStyle}`}
                  to={`${config.appUrl}`}
                >
                  {/* property/home-for-rent */}
                  Rent
                </Link>
              </li>
              <li className="nav-item nav-responsive mr-5">
                <span className="nav-link side" style={{ color: "#D5A117" }}>
                  {" "}
                  |{" "}
                </span>
              </li>
              <li className="nav-item mr-5">
                <div className="dropdown">
                  <a
                    className={`nav-link  ${fontStyle}`}
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    Propreneur
                    <i className={`bi bi-chevron-down text-white ms-2`}></i>
                  </a>
                  {isShown && (
                    <div className="dropdown-content shadow py-2">
                      <NavLink
                        to={`${config.appUrl}find-a-propreneur`}
                        className="font-saira profile-popup py-2"
                        style={{ color: "#122844" }}
                      >
                        Find a Propreneur
                      </NavLink>
                      <NavLink
                        to={`${config.appUrl}join-as-a-propreneur`}
                        className="font-saira profile-popup py-2"
                        style={{ color: "#122844" }}
                      >
                        Join as a Propreneur
                      </NavLink>
                    </div>
                  )}
                </div>
              </li>
              <li className="nav-item mr-10">
                {login_credential ? (
                  <div className="dropdown pt-0 d-flex ms-2">
                    <div className="profile-img">
                      <img
                        src={userImage}
                        alt="Icon"
                        className="rounded-circle bg-yellow"
                        style={{ padding: '1px' }}
                      />
                    </div>
                    <div ref={dropdownRef}>
                      <a
                        className="nav-link bottom-line text-yellow "
                        style={{ color: "white", cursor: 'pointer' }}
                        onClick={() => {
                          setUserDropdown(!userDropdown);
                          if (isShown)
                            setIsShown(false);
                        }}
                      >
                        {login_credential?.name?.split(' ')[0]}{""}
                      </a>
                      {userDropdown && (
                        <div className="dropdown-content logout-dropdown shadow">
                          {/* /user-profile */}
                          <NavLink
                            to={`${config.appUrl}user-profile`}
                            className={'profile-popup'}
                            style={{ color: "#122844" }}
                          >
                            Account Settings
                          </NavLink>
                          <NavLink
                            to={`${config.appUrl}email-settings`}
                            className="profile-popup py-1"
                            style={{ color: "#122844" }}
                          >
                            Email Settings
                          </NavLink>

                          <a onClick={handleLogout} style={{ color: "#122844" }}>
                            Logout
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <Link className="nav-link" to={`${config.appUrl}login`}>
                    <img className="icon-size" src={loginsvg} alt="userIcon" />
                  </Link>
                )}
              </li>
              <li className="nav-item mr-10">
                <a className="nav-link" href="#">
                  <img className="icon-size" src={customsvg} alt="icon" />
                </a>
              </li>

              <FooterSideBar url_location={url_location} />
            </ul>
          </div>
        </nav>
      </section>

      {/* Menubar for mobile */}
      <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
        <div className="sd-header">
          <div className="bd-cross-btn" onClick={ToggleSidebar}>
            <i className="fa fa-times"></i>
          </div>
        </div>
        <div>
          <div className="sd-bodys">
            <Link to={`${config.appUrl}`} className="text-white">Buy</Link>
          </div>
          <div className="sd-bodys">
            <Link to={`${config.appUrl}propreneur-experience`} className="text-white">Sell</Link>
          </div>
          <div className="sd-bodys">
            <Link to={`${config.appUrl}`} className="text-white">Rent</Link>
          </div>
          <div
            className="sd-bodys d-flex justify-content-between align-items-center"
            onClick={() => setProprenurDropdown(!proprenurDropdown)}
          >
            <a className="text-white">Propreneur</a>
            <i
              className={`bi bi-chevron-${proprenurDropdown ? "up" : "down"
                } text-white`}
            ></i>
          </div>
          <div
            className={`bg-yellow mx-3 ${proprenurDropdown ? "d-block" : "d-none"}`}
          >
            <div className="sd-bodys">
              <Link
                to={`${config.appUrl}find-a-propreneur`}
                className="text-dark-blue fw-600"
              >
                Find a Proprenur
              </Link>
            </div>
            <div className="sd-bodys">
              <Link
                to={`${config.appUrl}join-as-a-propreneur`}
                className="text-dark-blue fw-600"
              >
                Join as a Proprenur
              </Link>
            </div>
          </div>

          {/* If user is not logged In */}
          {!login_credential && (
            <div className="sd-bodys d-flex align-items-center">
              <img src={loginsvg} alt="userIcon" style={{ width: "24px" }} />
              <Link to={`${config.appUrl}login`} className="text-white ms-3 ">
                {" "}
                Login
              </Link>
            </div>
          )}

          {/* If user is logged In */}
          {login_credential && (
            <>
              <div
                className="sd-bodys d-flex justify-content-between align-items-center"
                onClick={() => setLoggedInDropdown(!loggedInDropdown)}
              >
                <a className="text-white">
                  <img
                    src={userImage}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                      padding: '1px'
                    }}
                    className="rounded-circle me-2 bg-yellow"
                    alt="userIcon"
                  />
                  {login_credential?.name}
                </a>
                <i
                  className={`bi bi-chevron-${loggedInDropdown ? "up" : "down"
                    } text-white`}
                ></i>
              </div>
              <div
                className={`bg-yellow mx-3 ${loggedInDropdown ? "d-block" : "d-none"
                  }`}
              >

                <div className="sd-bodys">
                  <Link
                    to={`${config.appUrl}user-profile`}
                    className="text-dark-blue fw-600"
                  >
                    Account Settings
                  </Link>
                </div>
                <div className="sd-bodys">
                  <Link
                    to={`${config.appUrl}email-settings`}
                    className="text-dark-blue fw-600"
                  >
                    Email Settings
                  </Link>
                </div>

                <div className="sd-bodys">
                  <a onClick={handleLogout} className="text-dark-blue fw-600">
                    Logout
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* fixed proprenuer floating button*/}
      {(pagesIncludesFloatBtn?.includes(url_location?.pathname)) && (
        <div
          className="fixed-propuser d-md-block d-none shadow-sm"
          onClick={() => setFloatingIcon(!floatingIcon)}
        >
          <div className="d-flex align-items-center text-start">
            <div className="key-icon">
              <img src={featureicon4} alt="floating icon" />
            </div>
            <div className={`ms-3 ${floatingIcon ? "d-block" : "d-none"}`}>
              <Link
                to={`${config.appUrl}contact-us`}
                className="text-white text-decoration-none"
              >
                Get Connected
              </Link>
              <Link
                to={`${config.appUrl}contact-us`}
                className=" text-decoration-none"
              >
                <h5 className="text-dark-light mt-2">www.propusers.com</h5>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
