import React, { useRef } from "react";

import Slider from "react-slick";
// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonials({ testimonialData,heading }) {

  const sliderRef = useRef();

  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div>
        <div className="pt-60 pb-80">
          <div>
            <div className="bd-main-service-section ">
              <div className="bd-dashed-line mr-10"></div>
              <div>
                <p className="bd-service-first-heading">{heading?.title}</p>
              </div>
              <div>
                <div className="bd-dashed-line ml-10"></div>
              </div>
            </div>
            <div className="pb-30">
              <div className="textCenter">
                <h2 className="bd-service-main-heading">{heading?.subtitle}</h2>
              </div>
            </div>
          </div>
          <div className="container-4 bd-responsive-cities">
            <Slider ref={sliderRef} {...blogsSettings}>
              {testimonialData?.map((review, i) => {
                return (
                  <div
                    key={review.id}
                    className="d-md-flex testimonial-card"
                  >
                    <div className="w-50 responsive-class">
                      <img
                        src={review?.image}
                        className="testimonial-img h-100"
                      />
                    </div>
                    <div className="w-50 responsive-class">
                      <div className="bd-slider-area  ">
                        <div>
                          <i
                            className="bi bi-quote display-3 lh-md text-yellow d-inline-block"
                            style={{ transform: "translateX(-10px)" }}
                          ></i>
                          <p
                            className="text-white lh-lg mb-0"
                            style={{
                              height: "148px",
                              overflow: "auto",
                              scrollbarWidth: "none",
                            }}
                          >
                            {review?.testimonial_comments}
                          </p>
                          <div>
                            <h5 className="bd-testimonial-head fs-2 mt-5">
                              {review?.name}
                            </h5>
                            <p className="text-white">{review?.profession} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div className="slider-btns text-end mt-4">
              <button onClick={() => sliderRef?.current?.slickPrev()}>
                <i className="bi bi-arrow-left"></i>
              </button>
              <button onClick={() => sliderRef?.current?.slickNext()}>
                <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
