import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import "../../Styles/FooterStyle/About.css";
import Slider from "react-slick";
import { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import ReadMore from "../../Common/ReadMore";

const ManagementTeam = () => {
  const [activeCard, setActiveCard] = useState(null);
  const [manageContent, setManageContent] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get(`/managementPage`)
      .then((res) => {
        setManageContent(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setManageContent({})
      controller.abort();
    };
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const teamSliderSettings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 5,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <div>
      {loading && <CustomLoader />}

      <div className="pt-90">
        <section className="py-5">
          <div className="px-5 pb-md-5">
            <div className="d-flex justify-content-center ">
              {
                manageContent?.titles &&
                <div className="mb-md-5 d-flex flex-column align-items-center ">
                  <div>

                    <h3 className="bd-header-overview">
                      <span className="color-yellow">
                        {manageContent?.titles[0]?.title?.split(' ')[0]}
                      </span> {manageContent?.titles[0]?.title?.split(' ')?.slice(1)}
                    </h3>

                    <div className="yellow-line"></div>
                  </div>
                  <p className="mt-3 font-saira fs-6">
                    {manageContent?.titles[0]?.subtitle}
                    {/* Meet the
                  <span className="font-saira text-dark fw-600">
                    {" "}
                    Extraordinarily Skilled
                  </span>{" "}
                  Team of
                  <span className="font-saira text-dark fw-600">
                    {" "}
                    PROPUSERS
                  </span> */}
                  </p>
                </div>
              }
            </div>

            <div className="main-team-box pb-5">
              {manageContent?.staff?.sort((a, b) => (a.sequence - b.sequence))?.map((team_data, index) => {
                return (
                  <div
                    key={team_data.id}
                    className={`row  ${index % 2 != 0 && "justify-content-end"
                      } manage-row `}
                  >
                    <div className="col-xl-7">
                      <ManagementCard
                        team_data={team_data}
                        position={index % 2 != 0 ? "right" : "left"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Top Propenuer */}
        <section className="py-5" style={{ backgroundColor: "#faf4e4" }}>
          <div className="container-box">
            {
              manageContent?.titles &&
              <div className="mb-5">
                <div className="d-flex justify-content-center">
                  <div>
                    <h3 className="bd-header-overview">
                      <span className="color-yellow">
                        {manageContent?.titles[1]?.title?.split(' ')[0]} </span>
                      {manageContent?.titles[1]?.title?.split(' ')?.slice(1)}
                    </h3>
                    <div className="yellow-line"></div>
                  </div>
                </div>
                <div>
                  <p className="text-dark mb-0 mt-3 font-saira text-center">
                    {manageContent?.titles[1]?.subtitle}
                  </p>
                </div>
              </div>
            }

            <div
              className="py-5 px-md-5 position-relative overflow-hidden"
              style={{ borderRadius: "20px" }}
            >
              <div className="row">
                <Slider {...sliderSettings} className="pb-4">
                  {/*    manageContent?.proprenuer */}
                  {manageContent?.backend?.sort((a, b) => (a.sequence - b.sequence))?.map((prop, i) => {
                    return (
                      <div
                        className="col-xxl-3 col-md-4 mb-md-0 mb-5 px-2"
                        key={prop.id}
                      >
                        <div
                          className="top_proprenuer_card p-3 bg-white"
                          onClick={() => setActiveCard(prop)}
                        >
                          <img src={prop.url} alt="" />

                          <div className="p-2 pt-4">
                            <h5 className="text-dark-blue">{prop.name}</h5>
                            <div className="text-yellow">{prop.designation}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {activeCard?.name && (
                <div
                  className="active_card_box"
                  onClick={() => setActiveCard(null)}
                >
                  <div className="row justify-content-end h-100">
                    <div className="col-md-4 h-100">
                      <div
                        className="top_proprenuer_card active_card h-100 bg-white"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <img src={activeCard?.url} alt="" />

                        <span
                          className="bi bi-x close-card-btn"
                          onClick={() => setActiveCard(null)}
                        ></span>

                        <div className="p-3 ">
                          <h5 className="text-dark-blue">{activeCard?.name}</h5>
                          <div className="text-yellow mb-2">
                            {activeCard?.designation}
                          </div>

                          <p className="prop_desc mb-1">
                            {activeCard?.description?.slice(0, 300)}..
                          </p>
                        </div>

                        <div className="d-flex justify-content-between align-items-end px-3">
                          <div>
                            <div>
                              <i className="fa fa-envelope text-yellow"></i>
                              <a
                                href={`mailto:${activeCard?.email}`}
                                className="ms-2 prop_desc"
                              >
                                {activeCard?.email}
                              </a>
                            </div>
                            <div>
                              <i className="fa fa-phone text-yellow"></i>
                              <a
                                href={`tel:${activeCard?.contact}`}
                                className="ms-2 prop_desc"
                              >
                                {activeCard?.contact}
                              </a>
                            </div>
                          </div>

                          <div>
                            <a href={activeCard?.twitter_link} target="_blank">
                              <i className="fa fa-twitter color-twitter px-md-2 px-1"></i>
                            </a>
                            <a href={activeCard?.fb_link} target="_blank">
                              <i className="fa fa-facebook color-facebook px-md-2 px-1"></i>
                            </a>
                            <a href={activeCard?.linkedin} target="_blank">
                              <i className="fa fa-linkedin color-linkedin px-md-2 px-1"></i>
                            </a>
                            <a href={activeCard?.insta_link} target="_blank">
                              <i className="fa fa-instagram color-instagram px-md-2 px-1"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="overflow-hidden">
          <div className="yellow-shade shade-design"></div>
          <div className="container-box  ">
            {
              manageContent?.titles &&
              <div className="mb-5">
                <div className="d-flex justify-content-center">
                  <div >
                    <h3 className="bd-header-overview">
                      <span className="color-yellow">
                        {manageContent?.titles[2]?.title?.split(' ')[0]} </span>
                      {manageContent?.titles[2]?.title?.split(' ')?.slice(1)}
                    </h3>
                    <div className="yellow-line"></div>
                  </div>
                </div>
                <div>
                  <p className="text-dark mb-0 mt-3 font-saira text-center">
                    {manageContent?.titles[2]?.subtitle}</p>
                </div>
              </div>
            }

            <div className="team-leads pb-md-4">
              <Slider {...teamSliderSettings} className="pb-5">
                {manageContent?.lead?.sort((a, b) => (a.sequence - b.sequence))?.map((item) => {
                  return <TeamLeadCard key={item.id} leadData={item} />;
                })}
              </Slider>
            </div>
          </div>
          <div className="blue-shade shade-design ms-auto"></div>
        </section>
      </div>
    </div>
  );
};

const ManagementCard = ({ team_data, position }) => {
  return (
    <div
      className={`d-md-flex pt-md-0 pt-5 ${position == "right" && "flex-row-reverse"
        }  justify-content-between`}
    >
      <div className="management-card">
        <div
          className={`d-flex ${position == "right" && "flex-row-reverse"
            } justify-content-between`}
        >
          <div className="fs-5">
            <a href={team_data?.fb_link} target="_blank">
              <i className="fa fa-facebook color-facebook pe-3"></i>
            </a>
            <a href={team_data?.twitter_link} target="_blank">
              <i className="fa fa-twitter color-twitter pe-3"></i>
            </a>
            <a href={team_data?.insta_link} target="_blank">
              <i className="fa fa-instagram color-instagram pe-3"></i>
            </a>
            <a href={team_data?.linkedin} target="_blank">
              <i className="fa fa-linkedin color-linkedin pe-3"></i>
            </a>
          </div>
          <div className={`${position == "left" && "text-end"}`}>
            <h4 className="font-saira fw-bold" style={{ color: "#444444" }}>
              {team_data.name}
            </h4>
            <p
              className="fw-600 font-saira mb-0 lh-sm text-yellow"
            // style={{ color: "#444444" }}
            >
              {" "}
              {team_data.designation}{" "}
            </p>
          </div>
        </div>
        <div
          style={{
            height: '220px',
            overflow: 'auto',
            scrollbarWidth: 'thin'
          }}
        >

          <p className="bd-text-team font-saira my-2">
            {/* <span className="fw-600">{team_data.name} </span> */}
            <ReadMore initialLength={300}>{team_data.description}</ReadMore>
          </p>
        </div>

        <div>
          <div className="d-flex justify-content-between bd-contact-team-sec">
            <div className="font-saira">
              <a
                href={`mailto:${team_data.email}`}
                className="text-dark-blue text-decoration-none"
                style={{ fontSize: "14px" }}
              >
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                {team_data.email}
              </a>
            </div>
            <div className="font-saira">
              <a
                href={`tel:${team_data.contact}`}
                className="text-dark-blue text-decoration-none"
                style={{ fontSize: "14px" }}
              >
                <FontAwesomeIcon icon={faPhoneVolume} className="me-1" /> +
                {team_data.contact}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${position}-image-design position-relative text-center my-md-0 my-5`}
      >
        <img
          src={team_data.url}
          alt="team"
          style={{ backgroundColor: '#cbced1' }}
          className={`propenuer-polygon-image2 team-${position}-image`}
        />
        <div className={`${position}-design-line`}></div>
      </div>
    </div>
  );
};

const TeamLeadCard = ({ leadData }) => {
  return (
    <div className="team-card border mb-md-0 mb-5 mx-2">
      <div className="team-img-box">
        <img src={leadData?.url} alt="" />
      </div>
      <div className="team-info">
        <div className="p-2 text-center">
          <h5 className=" text-yellow fw-">{leadData?.name}</h5>
          <p className="mb-0 lh-sm fw-600"> {leadData?.designation} </p>
        </div>
        <div className="text-center fs-5 pb-2">
          <a href={leadData?.twitter_link} target="_blank">
            <i className="fa fa-twitter color-twitter px-2"></i>
          </a>
          <a href={leadData?.fb_link} target="_blank">
            <i className="fa fa-facebook color-facebook px-2"></i>
          </a>
          <a href={leadData?.linkedin} target="_blank">
            <i className="fa fa-linkedin color-linkedin px-2"></i>
          </a>
          <a href={leadData?.insta_link} target="_blank">
            <i className="fa fa-instagram color-instagram px-2"></i>
          </a>
        </div>
      </div>
      <div className="hover-team-content ">
        {leadData?.description
          ? leadData?.description?.slice(0, 160)
          : "Lorem ipsum homolig beskap juholtare antinas. Kuvis laliga pansocial donöv. Epirednyra de bens för att kapselbry gga re plar i ditökar. Olig vidonat. Hemir safaling anaren."}

        <div className="text-center fs-5 mt-4">
          <a href={leadData?.twitter_link} target="_blank">
            <i className="fa fa-twitter color-twitter px-2"></i>
          </a>
          <a href={leadData?.fb_link} target="_blank">
            <i className="fa fa-facebook color-facebook px-2"></i>
          </a>
          <a href={leadData?.linkedin} target="_blank">
            <i className="fa fa-linkedin color-linkedin px-2"></i>
          </a>
          <a href={leadData?.insta_link} target="_blank">
            <i className="fa fa-instagram color-instagram px-2"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ManagementTeam;
