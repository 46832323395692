import React, { useEffect, useState } from "react";
import "../../assets/Css/PagesUnderHome.css";
import CityAreaCard from "./CityAreaCard";
import { Link, useParams } from "react-router-dom";
import config, { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";

const PropUserLocality = () => {
  const { city_id } = useParams();

  const [localities, setlocalities] = useState([]);
  const [city_details, setCityDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .post(`/localities`, { city_id })
      .then((response) => {
        setlocalities(response.data.data?.filter(item => item.status == '1'));
        setCityDetails({
          name: response.data.city_data?.city_name,
          logitude: response.data.city_data?.lng,
          latitude: response.data.city_data?.lat,
          city_image: response.data.city_data?.city_image,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [city_id]);

  const city_latitute = city_details?.latitude;
  const city_longitude = city_details?.logitude;
  // ? city_details?.logitude?.match(/[\d.]+/)[0] : city_details?.logitude
  // ? city_details?.longitude?.match(/[\d.]+/)[0] : city_details?.longitude
  return (
    <>
      {loading && <CustomLoader />}

      <section className="pt-90">
        <section
          className="propuser_locality_banner px-4"
          style={{
            // https://demo.propusers.com/admin/public/images/cities/$
            backgroundImage: `linear-gradient(#111111b0, #111111b0),url("${city_details?.city_image}")`,
          }}
        >
          <div className="px-2">
            <div className="pt-4 ">
              <h4 className="bd-header-overview bd-header-overview2 text-yellow">
                <span className="text-white fs-3 fw-light">
                  {city_details?.name}
                </span>{" "}
                Guides
              </h4>
              <div className="yellow-line">&nbsp;</div>
            </div>

            <div className="row justify-content-end">
              <div className="col-md-5 pt-5 pb-4 ps-5">
                <div className="ps-2 ">
                  <div className="temperature-badge bg-grey-transparent mx-auto font-saira">
                    {city_latitute}
                    ° N
                    <span className="text-yellow px-1">|</span>
                    {city_longitude}
                    ° E
                  </div>

                  <div>
                    <h2 className="text-yellow display-6 my-2">Explore</h2>
                    <h2 className="text-white fw-bold display-3">
                      {city_details?.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="pt-4 my-3 pb-4 px-4">
        <div className="px-2">
          <div className="row">
            {localities.map((city, i) => {
              return i == 1 || i == 5 || i == 6 || i == 10 ? (
                <div key={city.id} className="col-xl-6 col-lg-4 col-sm-6 mb-4">
                  <Link
                    to={`${config.appUrl}propuser-locality-detail/${city.id}`}
                  >
                    <CityAreaCard city={city} />
                  </Link>
                </div>
              ) : (
                <div key={city.id} className="col-xl-3 col-lg-4 col-sm-6  mb-4">
                  <Link
                    to={`${config.appUrl}propuser-locality-detail/${city.id}`}
                  >
                    <CityAreaCard city={city} />
                  </Link>
                </div>
              );
            })}

            {localities.length == 0 && (
              <h2 className="text-secondary text-center">No Locality Found </h2>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PropUserLocality;
