import React, { useContext, useEffect, useState } from 'react'
import "../../Styles/FooterStyle/Career.css"
import { axiosbaseurl } from '../../config'
import toast, { Toaster } from 'react-hot-toast'
import CustomLoader from '../CustomLoader'
import { customToastOptions } from '../../Styles/popup_style'
import { useParams } from 'react-router-dom'
// import bottom_image_design from "../../assets/Bottom_Bg_design.jpg"
// import { AppGlobalContext } from '../../GlobalContext'

const JobDetails = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [position, setPosition] = useState('');
    const [resume, setResume] = useState('');
    const [loading, setLoading] = useState('');
    const [jobDetails, setJobDetails] = useState({});
    const [allJobPosts, setAllJobPosts] = useState([]);

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        position: '',
        resume: '',
    });

    const { post_slug } = useParams()

    // const { current_location } = useContext(AppGlobalContext);


    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        axiosbaseurl.get(`/job/${post_slug}`)
            .then((res) => {
                setJobDetails(res.data.data[0])
                setPosition(res.data.data[0]?.id)

                axiosbaseurl.get(`/careerPage`)
                    .then(res => {
                        setAllJobPosts(res.data.jobs?.job_postings)
                    })
                    .catch((err) => console.log(err))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => setLoading(false))

        return () => {
            setJobDetails({})
            setAllJobPosts([])
            setPosition('')
            controller.abort();
        };
    }, [])

    const handleValidation = () => {

        const newErrors = {};

        if (!name) {
            newErrors.name = 'Name is required';
        }

        if (!position) {
            newErrors.position = 'Please select the position to apply for';
        }

        if (!resume) {
            newErrors.resume = 'Please Upload you resume';
        }

        if (!phone) {
            newErrors.phone = 'Phone Number is required';
        } else if (!/^([+]\d{2})?\d{10}$/.test(phone)) {
            newErrors.phone = 'Invalid Phone Number';
        }

        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            newErrors.email = 'Invalid email format';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const clearfeilds = () => {
        setName("");
        setEmail("");
        setPhone("");
        setPosition("");
        setResume("");
    }

    const handleSubmitForm = async () => {

        if (handleValidation()) {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append('fname', name)
                formData.append('email', email)
                formData.append('phone', phone)
                formData.append('position', position)
                formData.append('file', resume)
                formData.append('type', "career")
                formData.append("ip_location", jobDetails?.job_location)

                const response = await axiosbaseurl.post('/newCareer', formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                })
                setLoading(false)

                if (response.data.success == true) {

                    toast.success("Your details has been sent successfully !!");

                    const toastDuration = 3000;

                    setTimeout(() => {
                        clearfeilds();
                        setErrors({});
                        window.scrollTo(0, 0);
                    }, toastDuration);
                }
            } catch (error) {
                console.log(error)
                toast.error('Something went wrong !')
            }

        }
    }

    return (
        <div>
            {loading && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />


            <div className='pt-90'>
                <div className='px-md-5 px-1 mb-5'>


                    <div className="row sitemap-section  " >
                        <div className="col-1 d-md-block d-none position-relative ">
                            <div className='bg-yellow h-100'>
                                <div className='key-icon bg-dark-blue d-md-block d-none'></div>
                            </div>
                        </div>

                        <section className="col-md-11 py-4 px-md-0 editor_main_content "
                            style={{ backgroundColor: '#faf8f4' }}>

                            <div>
                                <div className='pb-0'>
                                    <div className='carrier-page1'>
                                        <div className='carrier-text mb-4 fs-5'>
                                            <span style={{ fontWeight: 'bold' }}>Carrier/</span> <span style={{ color: 'grey' }}>
                                                {jobDetails?.title}
                                            </span>
                                        </div>
                                        <div className='flex-div pe-0 post-details'>
                                            <div className='about-div'>
                                                <div className='bg-white p-md-4 p-3 mb-4 shadow-sm'>
                                                    <div className='about-company '>
                                                        <span className='span1'>About</span>  <span className='span2'>Company</span>
                                                    </div>
                                                    <div>
                                                        <p className='job-paragraph'>
                                                            {jobDetails?.company}
                                                        </p>
                                                    </div>
                                                    <div className='about-company'>
                                                        <span className='span1'>Job</span>  <span className='span2'>Description</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        Role & responsibilities
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: jobDetails?.description }} />
                                                </div>

                                                <div className='bg-white p-md-4 p-3 mb-4 shadow-sm'>
                                                    <div className='about-company'>
                                                        <span className='span1'>Your</span>  <span className='span2'>Requirement \ Responsiblities</span>
                                                    </div>
                                                    <div>
                                                        <div dangerouslySetInnerHTML={{ __html: jobDetails?.job_responsibilities }} />
                                                    </div>

                                                </div>

                                                <div className='bg-white p-md-4 p-3 mb-4 shadow-sm'>
                                                    <div className='about-company'>
                                                        <span className='span1'>Your</span>  <span className='span2'>Qualification</span>
                                                    </div>
                                                    <div>
                                                        {/* Up to three years of experience as a Junior Designer */}
                                                        <div dangerouslySetInnerHTML={{ __html: jobDetails?.educational_requirements }} />
                                                    </div>

                                                </div>

                                                <div className='bg-white p-md-4 p-3 mb-md-0 mb-4 shadow-sm'>

                                                    <div className='about-company'>
                                                        <span className='span1'>Key</span>  <span className='span2'>Skills</span>
                                                    </div>
                                                    <div className=''>
                                                        {
                                                            jobDetails?.skills?.split(',')?.map(skill => {
                                                                return <button key={skill} className='job-button job-btn me-md-3 me-3 mt-3'>
                                                                    <i className='bi bi-star-fill me-2'></i>
                                                                    {skill}
                                                                </button>
                                                            })
                                                        }
                                                    </div>
                                                    <div className='mt-5 mb-4' >
                                                        <a href='#ApplyNow' className='blue-btn px-5 py-3 text-decoration-none'>Apply
                                                            <i className='bi bi-arrow-right text-yellow ms-2  '></i> </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='team-div' style={{ flex: 1 }}>

                                                <div className='bg-white p-md-4 p-3 mb-5 shadow-sm'>

                                                    <div className='team-title '>
                                                        <span className='team-span'>Team</span>
                                                        <div className="yellow-line mb-3"></div>

                                                        <span className='span2 text-dark-blue'>{jobDetails?.team}</span>
                                                    </div>
                                                    <div>
                                                        <span className='team-span'>Job Role</span>
                                                        <div className="yellow-line mb-3"></div>
                                                        <span className='span2 text-dark-blue'>{jobDetails?.title}</span>
                                                    </div>
                                                    <div className='salary'>
                                                        <div className='fw-600 text-dark-blue'>
                                                            <i className="bi bi-briefcase me-2"></i>  {jobDetails?.experience} Years
                                                            <span className='ps-4'>
                                                                <strong className='fw-bold'> {jobDetails?.salary?.split('/')[0]}</strong>
                                                                /{jobDetails?.salary?.split('/')[1]}</span>
                                                        </div>
                                                    </div>

                                                    <div className='mt-2'>
                                                        <div>
                                                            <span className='team-span'>Total Openings</span>
                                                            <div className="yellow-line mb-3"></div>
                                                            <span className='span2 text-dark-blue'>{jobDetails?.openings}</span>
                                                        </div>
                                                    </div>

                                                    <div className='skills '>
                                                        <div className='mt-4'>
                                                            {
                                                                jobDetails?.job_type?.split(',')?.map((item, i) => {
                                                                    return <button className={`job-button job-btn me-2 text-capitalize  ${i !== 0 && 'mt-md-0 mt-2'}`}>
                                                                        {item}
                                                                    </button>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='location mt-4' >
                                                        <div>
                                                            <span className='team-span'>Location</span>
                                                            <div className="yellow-line mb-3"></div>
                                                            <i className="bi bi-geo-alt"></i> &nbsp;
                                                            <a href={jobDetails?.map_link} target='_blank' className='text-dark-blue text-decoration-none' style={{ opacity: '.6' }}>
                                                                {jobDetails?.job_location}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className='my-4' >
                                                        <a href='#ApplyNow' className='blue-btn px-5 py-3 text-decoration-none'>Apply
                                                            <i className='bi bi-arrow-right text-yellow ms-2  '></i> </a>
                                                    </div>
                                                </div>

                                                <div className='mb-md-0 mb-4 shadow-sm'>
                                                    <div id="ApplyNow">
                                                        <div className='carrier-form bg-dark-blue text-white mt-1' >
                                                            <h3 className='text-start'>
                                                                <span className='span1'>Discover </span>
                                                                <span className='span2 text-white fs-3 fw-500'>Your New Career</span>
                                                            </h3>
                                                            <div className='mt-3'>
                                                                Fill your details and share with us. We will connect with you soon
                                                            </div>
                                                            <div className='row form-div'>
                                                                <div className='col-12 pb-4'>
                                                                    <label className='text-white fs-6'>Name</label>
                                                                    <br />
                                                                    <input type='text' className='form-control' value={name} placeholder='Name'
                                                                        onChange={(e) => {
                                                                            setName(e.target.value)
                                                                        }}
                                                                    ></input>
                                                                    {
                                                                        errors.name && <span className='text-danger badge'>{errors.name}</span>
                                                                    }
                                                                </div>
                                                                <div className='col-12 pb-4'>
                                                                    <label className='text-white fs-6'>Email ID</label>
                                                                    <br />
                                                                    <input type='text' className='form-control' value={email} placeholder='Email ID'
                                                                        onChange={(e) => {
                                                                            setEmail(e.target.value)
                                                                        }}
                                                                    ></input>
                                                                    {
                                                                        errors.email && <span className='text-danger badge'>{errors.email}</span>
                                                                    }
                                                                </div>
                                                                <div className='col-12 pb-4'>
                                                                    <label className='text-white fs-6'>Phone No.</label>
                                                                    <br />
                                                                    <input type='text' className='form-control' value={phone}
                                                                        placeholder='Phone No.'
                                                                        onChange={(e) => {
                                                                            setPhone(e.target.value)
                                                                        }}
                                                                        onKeyPress={(e) => {
                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    ></input>
                                                                    {
                                                                        errors.phone && <span className='text-danger badge'>{errors.phone}</span>
                                                                    }
                                                                </div>
                                                                <div className='col-12 pb-4'>
                                                                    <label className='text-white fs-6'>Position Applied For</label>
                                                                    <br />
                                                                    <select value={position}
                                                                        onChange={(e) => {
                                                                            setPosition(e.target.value)
                                                                        }}
                                                                        className='form-control'
                                                                    >

                                                                        <option value="">-- Select --</option>
                                                                        {
                                                                            allJobPosts?.map(job => {
                                                                                return (
                                                                                    <option key={job.id} value={job.id}> {job.title} </option>
                                                                                )
                                                                            })
                                                                        }
                                                                        <i className="bi bi-arrow-down"></i>
                                                                    </select>

                                                                    {
                                                                        errors.position && <span className='text-danger badge'>{errors.position}</span>
                                                                    }
                                                                </div>
                                                                <div className='col-12 pb-4'>
                                                                    <label className='text-white fs-6'>Upload Resume <span style={{ color: '#ff0000' }}> (Only Pdf & Docx file) </span></label>
                                                                    <br />
                                                                    <input type='file' className='file-yellow-btn' value={resume}
                                                                        onChange={(e) => {
                                                                            let fileData = e.target.files[0]
                                                                            if (fileData?.type !== 'application/pdf' &&
                                                                                fileData?.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                                                                                setErrors({ ...errors, resume: 'Only Pdf & Docx file accepted' })
                                                                                setResume('')
                                                                                return
                                                                            } else {
                                                                                setErrors({})
                                                                                setResume(e.target.value)
                                                                            }
                                                                        }}
                                                                    ></input>
                                                                    {
                                                                        errors.resume && <span className='text-danger badge'>{errors.resume}</span>
                                                                    }
                                                                </div>
                                                                <div className='my-3 submit-btn'>
                                                                    <button onClick={handleSubmitForm}>Submit</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </section>

                    </div>

                </div>
            </div>
            {/* <div>
                <img src={bottom_image_design} alt="" className='img-fluid' />
            </div> */}

        </div>
    )
}

export default JobDetails