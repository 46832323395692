import React, { useRef, useState } from 'react'
import "../../Styles/BuyPageStyle/BuyVisiting.css"
// import personImg from "../../assets/BuyDetailPage/Details.png"
// import houseImg from "../../assets/BuyDetailPage/Details2.png"
import Calendar from 'react-calendar';
import "../../Styles/Calender.css"
import googleDuoImg from "../../assets/BuyDetailPage/google-duo.png"
import googleMeetImg from "../../assets/BuyDetailPage/meet.png"
import skypeImg from "../../assets/BuyDetailPage/skype.png"
import whatsapp from "../../assets/BuyDetailPage/whatsapp.png"
import zommImg from "../../assets/BuyDetailPage/zoom.png"
// import sucessImg from "../../assets/BuyDetailPage/success.png"
import 'react-calendar/dist/Calendar.css';
import { Modal } from 'antd';

const BuyVisitingTime = () => {

    const [value, onChange] = useState(new Date());
    const [videoModal, setVideoModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const [schedule_type, setSchedule_type] = useState('person');
    const [virtual_tour, setVirtual_tour] = useState('WhatsApp');

    // user
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('+91 ');

    const [errors, setErrors] = useState({})

    const length = 4

    const initialOtp = Array(length).fill('')
    const [otpValues, setOtpValues] = useState(initialOtp);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleInputChange = (index, event) => {
        const { value } = event.target;
        const newValues = [...otpValues];
        newValues[index] = value;
        setOtpValues(newValues);

        // Move to the next input when a digit is entered
        if (value !== '' && index < length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleBackspace = (index, event) => {
        if (event.keyCode === 8 && index > 0 && otpValues[index] === '') {
            inputRefs[index - 1].current.focus();
        }
    };

    // // validations for first form
    const handleValidation = () => {
        const newErrors = {};

        if (!name) {
            newErrors.name = 'Name is required';
        } else if ((/\d/).test(name)) {
            newErrors.name = 'Digits not allowed in name';
        }

        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            newErrors.email = 'Invalid email format';
        }

        if (!phone) {
            newErrors.phone = 'Phone No. is required';
        } else if (phone.length < 7 || phone.length > 15) {
            newErrors.phone = 'Invalid phone number';
        }

        setErrors(newErrors)
        return (Object.keys(newErrors).length === 0);
    }
    // ===========================

    // hhandling modal steps
    const handleSubmitVideo = (e) => {
        e.preventDefault()
        setVideoModal(false)
        setUserModal(true)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setUserModal(false)
            setOtpModal(true)
        }
    }
    const handleVerifyOtp = (e) => {
        e.preventDefault()
        setOtpModal(false)
        setFinishModal(true)
    }


    const videoOptions = [
        {
            img: googleDuoImg,
            name: "Google Duo"
        },
        {
            img: skypeImg,
            name: "Skype"
        },
        {
            img: zommImg,
            name: "Zoom"
        },
        {
            img: whatsapp,
            name: "WhatsApp"
        },
        {
            img: googleMeetImg,
            name: "Google Meet"
        }
    ]

    return (
        <div>
            <section className="pt-120 pb-60" style={{ backgroundColor: '#f4f4f4' }}>
                <div>
                    <div className='px-md-5 px-4'>

                        <div className="row">
                            <div className="col-lg-8">
                                <div className='schedule-header d-md-flex justify-content-between mb-5'>
                                    <div>
                                        <h1 className='fw-500 font-saira mb-1'>Schedule Visit</h1>
                                        <div className="yellow-line"></div>
                                    </div>
                                    <div>
                                        <div className='bg-white d-md-flex justify-content-between p-1 
                                        header-switcher rounded custom-box-shadow mt-md-0 mt-4'>
                                            <div className='font-saira'>Book Your Slots</div>
                                            <div className='font-saira active'>Common Visiting Hour</div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <h3 className='font-saira text-dark-blue mb-1'>Select a Date and Time</h3>
                                        <div className="yellow-line"></div>
                                    </div>

                                    <div className='schedule-date-box custom-box-shadow bg-white p-sm-4 p-3 mt-4'>
                                        <div className='row'>
                                            <div className="col-lg-5 custom-calendar pe-md-0">
                                                <div className='mb-4'>
                                                    <h4 className='font-saira text-dark-blue mb-1'>Date</h4>
                                                    <div className="yellow-line"></div>
                                                </div>
                                                <Calendar onChange={onChange} value={value} />
                                            </div>
                                            <div className='col-lg-7 ps-md-4 mt-md-0 mt-5'>
                                                <div className='mb-4'>
                                                    <h4 className='font-saira text-dark-blue mb-1'>Time</h4>
                                                    <div className="yellow-line"></div>
                                                </div>

                                                <div className='schedule-time-box'>
                                                    <div className='row'>
                                                        <div className="col-4 text-center">
                                                            <h5 className='mb-0 font-saira text-444'>Morning</h5>

                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <h5 className='mb-0 font-saira text-444'>Afternoon</h5>

                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <h5 className='mb-0 font-saira text-444'>Evening</h5>

                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                            <div className='time-box'>09:00am</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='text-center'>
                                                    <button value={'person'}
                                                        onClick={(e) => setSchedule_type(e.target.value)}
                                                        className={`schedule-btn ${schedule_type === 'person' && 'blue-button'} mt-4 mx-2`}>
                                                        <i className='bi bi-person-fill-down fs-5 me-2'></i>
                                                        Tour in Person
                                                    </button>
                                                    <button value={'video'}
                                                        onClick={(e) => setSchedule_type(e.target.value)}
                                                        className={`schedule-btn ${schedule_type === 'video' && 'blue-button'} mt-4 mx-2`}>
                                                        <i className='bi bi-wechat fs-5 me-2'></i>
                                                        Tour via Video
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='text-center mt-5'>
                                            <button className='schedule-btn blue-btn fw-600 py-2 mx-2'
                                                onClick={() => {
                                                    (schedule_type === 'video') ? setVideoModal(true) : setUserModal(true)
                                                }}>
                                                Save and Next
                                            </button>
                                            <button className='schedule-btn fw-600 text-dark-blue py-2 mx-2'>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 ps-md-4 mt-md-0 mt-5">
                                <div>
                                    <div className='ms-3 mb-4'>
                                        <h3 className='font-saira text-dark-blue mb-1'>Details</h3>
                                        <div className="yellow-line"></div>
                                    </div>

                                    <div className='schedule-date-box custom-box-shadow mb-4 bg-white p-3'>
                                        <div className='d-flex align-items-center p-3  schedule-prop-detail mb-3'>
                                            <div>
                                                <img src="https://plus.unsplash.com/premium_photo-1664533227571-cb18551cac82?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFufGVufDB8fDB8fHww" alt="" />
                                            </div>
                                            <div className='ms-4'>
                                                <h5 className='fw-500 text-dark-blue font-saira'>Manoj Kumar Yadav</h5>
                                                <p className='mb-0 fw-500 font-saira paragraph2'>Propreneur</p>
                                            </div>
                                        </div>

                                        <div className='d-md-flex align-items-center p-3  schedule-prop-detail schedule-prop-detail2'>
                                            <div>
                                                <img src="https://plus.unsplash.com/premium_photo-1664533227571-cb18551cac82?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFufGVufDB8fDB8fHww" alt="" />
                                            </div>
                                            <div className='ms-md-3 mt-md-0 mt-4'>
                                                <h4 className='paragraph2 font-saira fw-600 mb-1'>₹ 42,00,000</h4>
                                                <h5 className='fw-500 text-dark-blue font-saira mb-1'>Vishnudhara Garden</h5>
                                                <p className='mb-0 font-saira paragraph2 fw-500 fs-6'>23, Indrapurma, 16th Street, Colony, Domlur, Bengaluru.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='schedule-date-box custom-box-shadow bg-white p-3'>
                                        <div className='mb-4'>
                                            <h4 className='font-saira text-dark-blue mb-1'>Summary</h4>
                                            <div className="yellow-line"></div>
                                        </div>
                                        <div className='p-3 schedule-prop-detail'>
                                            <div >
                                                <div className='d-flex'>
                                                    <h5 className='fw-500 text-dark-blue font-saira mb-1'>Date:</h5>
                                                    <p className='mb-0 fw-500 font-saira paragraph ms-2'>Fri,16th Sep 2022</p>
                                                </div>
                                                <div className='d-flex my-2'>
                                                    <h5 className='fw-500 text-dark-blue font-saira mb-1'>Time to visit:</h5>
                                                    <p className='mb-0 fw-500 font-saira paragraph ms-2'><span className='paragraph2 font-saira' > 04:00pm </span> Afternoon</p>
                                                </div>
                                                <div className='d-sm-flex'>
                                                    <h5 className='fw-500 text-dark-blue font-saira mb-sm-1 mb-3'>Visit Option:</h5>
                                                    <div>
                                                        <button className='schedule-btn ms-md-2 text-blue'>
                                                            <i className='bi bi-person-fill-down fs-5 me-2'></i>
                                                            Tour in Person
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* Video form modal */}
            <Modal
                open={videoModal}
                title={""}
                width={500}
                centered
                maskClosable={false}
                className='schedule-visit-modal'
                onCancel={() => setVideoModal(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <form onSubmit={handleSubmitVideo}>
                        <h2 className='fs-3 fw-500 font-saira text-dark-blue'>Choose Your Virtual Tour Options</h2>
                        <div className='py-4'>
                            <div className="row">
                                {
                                    videoOptions.map((video, index) => {
                                        return (
                                            <div key={index} className="col-sm-6 mb-3">
                                                <div className={`d-flex align-items-center video-option p-3 
                                                ${virtual_tour === video.name && 'blue-button'}`}
                                                    style={{ borderRadius: '8px', cursor: 'pointer', border: '1px solid #B2B2B2' }}
                                                    onClick={() => setVirtual_tour(video.name)}
                                                >
                                                    <div className='bg-white rounded-pill' >
                                                        <img className="" src={video.img} />
                                                    </div>
                                                    <div className='ms-3'>
                                                        <h5 className={`mb-0 ${virtual_tour === video.name ? 'text-white' : 'text-dark-blue'}  font-saira fw-600`}>{video.name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='mb-4'>
                            <button type='submit' className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>Save and Next</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* user details form modal */}
            <Modal
                open={userModal}
                title={<div className="px-4 py-3" style={{ borderRadius: '10px', backgroundColor: '#F0F0F0' }}>
                    <div className='d-flex align-items-center schedule-prop-detail'>
                        <div>
                            <img src="https://plus.unsplash.com/premium_photo-1664533227571-cb18551cac82?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFufGVufDB8fDB8fHww" alt="" />
                        </div>
                        <div className='ms-4'>
                            <h5 className='fw-500 text-dark-blue font-saira mb-0'>Manoj Kumar Yadav</h5>
                            <p className='mb-0 fw-500 font-saira paragraph2'>Propreneur</p>
                        </div>
                    </div>
                </div>}
                width={500}
                maskClosable={false}
                centered
                className='schedule-visit-modal'
                onCancel={() => setUserModal(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <form onSubmit={handleSubmitForm}>
                        <div className='mb-3'>
                            <label htmlFor="" className='font-saira fs-6 mb-1'>Name</label>
                            <input type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className={`form-control font-saira py-2 ${errors.name && 'border-danger'}`} placeholder='Enter Name' />
                            {
                                errors.name &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.name}</span>
                            }
                        </div>
                        <div className='mb-3'>
                            <label htmlFor="" className='font-saira fs-6 mb-1'>Email</label>
                            <input type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`form-control font-saira py-2 ${errors?.email && 'border-danger'}`} placeholder='Enter Email' />
                            {
                                errors?.email &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.email}</span>
                            }
                        </div>
                        <div >
                            <label htmlFor="" className='font-saira fs-6 mb-1'>Mobile</label>
                            <input type="text"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key))
                                        event.preventDefault();
                                }}
                                value={phone}
                                onChange={(e) => {
                                    let phonevalue = e.target.value;
                                    if (phonevalue.startsWith('+91 ')) {
                                        setPhone(phonevalue)
                                    } else {
                                        setPhone('+91 ' + phonevalue.replace(/^(\+91\s*)/, ''));
                                    }
                                }}
                                maxLength={14}
                                className={`form-control font-saira py-2 ${errors?.phone && 'border-danger'}`}
                                placeholder='Enter Mobile' />
                            {
                                errors?.phone &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.phone}</span>
                            }
                        </div>
                        <div className='mt-5 mb-4'>
                            <button type='submit' className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>Save and Next</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* otp modal */}
            <Modal
                open={otpModal}
                title={""}
                width={500}
                centered
                className='schedule-visit-modal '
                closeIcon={false}
                maskClosable={false}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <form onSubmit={handleVerifyOtp}>
                        <div className='text-center my-4'>
                            <h3 className='text-dark-blue font-saira mb-0 fw-500'>Verify your Phone number</h3>
                            <h5 className='paragraph2 font-saira py-4'>Code is sent to 1800 000 000</h5>
                        </div>
                        <div className='row px-4'>
                            {otpValues.map((value, index) => (
                                <div className="col px-2" key={index}>
                                    <input
                                        key={index}
                                        ref={inputRefs[index]}
                                        type="text"
                                        className='fs-6 form-field bg-white px-1 text-center text-dark py-2'
                                        style={{ boxShadow: '0px 3px 6px #00000029' }}
                                        maxLength={1}
                                        value={value}
                                        placeholder='0'
                                        onChange={(e) => handleInputChange(index, e)}
                                        onKeyDown={(e) => handleBackspace(index, e)}
                                        required
                                    />
                                </div>
                            ))}
                        </div>

                        <div className='py-5 text-center'>
                            <p className='paragraph2 fw-600 font-saira mb-0'>Don’t receive code?</p>
                            <a href="#" className='font-saira text-decoration-none fw-500' style={{ color: '#16A9EA' }}> Request to Get via Registered Phone</a>
                        </div>

                        <div className='mb-4'>
                            <button type='submit' className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>Finish</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* finished modal */}
            <Modal
                open={finishModal}
                title={""}
                width={500}
                maskClosable={false}
                centered
                className='schedule-visit-modal'
                onCancel={() => setFinishModal(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <div className='mt-5'>
                        <div className='text-center my-5'>
                            <i className='bi bi-patch-check-fill display-3' style={{ color: '#39B54A' }}></i>
                            <h3 className='text-dark-blue font-saira mb-0 mt-2 fw-500'>Thank You!</h3>
                            <p className='text-dark-blue font-saira mb-0 py-3'>Your have been Submitted all information successfully</p>
                            <h5 className='text-dark-blue font-saira '>Soon we will contact you.</h5>
                        </div>

                        <div className='mb-4 pt-4'>
                            <button onClick={() => setFinishModal(false)} className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>Okay Done</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default BuyVisitingTime