import React, { useEffect } from 'react'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';

import icon1 from "../../assets/footer/pointer1.png"
import icon2 from "../../assets/footer/pointer.png"
import icon3 from "../../assets/footer/pointer_red.png"

// 20.5937, 78.9629
const position = [23, 80.962883];

const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [20, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const customMarkerIcon2 = L.icon({
    iconUrl: icon2,
    iconSize: [16, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const customMarkerIcon3 = L.icon({
    iconUrl: icon3,
    iconSize: [15, 22],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const countryStyle = {
    color: 'black',
    opacity: 1,
    height: '100%',
    width: '100%'
};

const IndianMap = ({
    headquarters,
    regionalOffice,
    multiLocation
}) => {

    // const map = useMap();


    // {
    //     name: 'Noida City',
    //     latitude: 28.5355,
    //     longitude: 77.3910,
    //     address: '123, Noida, India',
    //     contact: '123-456-7890',
    // }

    return (
        <>
            <div id='map' className='w-100 h-100 '>
                <MapContainer
                    center={position}
                    zoom={4}
                    zoomControl={false}
                    scrollWheelZoom={false}
                    doubleClickZoom={false}
                    touchZoom={false}
                    boxZoom={false}
                    style={countryStyle}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {multiLocation && multiLocation?.map((location, index) => (
                        <Marker key={index}
                            position={[location.latitude, location.longitude]}
                            icon={customMarkerIcon}
                        >
                            <Popup
                                autoPan={false}
                                closeButton={false}
                                offset={[135, 100]}
                            >
                                <PopUpBox location={location} />
                            </Popup>
                        </Marker>
                    ))}

                    {headquarters && headquarters?.map((location, index) => (
                        <Marker key={index}
                            position={[location.latitude, location.longitude]}
                            icon={customMarkerIcon2}
                        >
                            <Popup
                                autoPan={false}
                                closeButton={false}
                                offset={[135, 100]}
                            >
                                <PopUpBox location={location} />
                            </Popup>
                        </Marker>
                    ))}

                    {regionalOffice && regionalOffice?.map((location, index) => (
                        <Marker key={index}
                            position={[location.latitude, location.longitude]}
                            icon={customMarkerIcon3}
                        >
                            <Popup
                                autoPan={false}
                                closeButton={false}
                                offset={[135, 100]}
                            >
                                <PopUpBox location={location} />
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
        </>
    )
}

export default IndianMap

const PopUpBox = ({ location }) => {
    return (
        <div className=" ">
            <div>
                <h6 className='font-saira fw-600'>{location?.company}</h6>
                <p className="my-1 font-saira">{location?.address}</p>
            </div>
            <div className='mt-2'>
                <span className="pr-10 text-dark-blue">
                    <i className='bi bi-envelope'></i>
                </span>
                <a href={`mailto:${location?.email}`}
                    className='bd-info-loca font-saira text-decoration-none fw-500'>{location?.email}</a>
            </div>
            <div className='mt-1'>
                <span className="pr-10 text-dark-blue">
                    <i className='bi bi-telephone'></i>
                </span>
                <a href={`tel:${location?.phone}`}
                    className='bd-info-loca font-saira text-decoration-none fw-500'>{location?.phone}</a>
            </div>
        </div>
    )
}