import React, { useEffect } from 'react'

import propuserlogo from '../../assets/Propusers_logo-White.png';
import LoginPage2 from '../../assets/Loginpage2.png';
import '../../Styles/Comman.css';
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useState } from 'react';
import config, { axiosbaseurl } from '../../config';
import swal from 'sweetalert';
// import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';
import secureLocalStorage from 'react-secure-storage';

const SignUPMain = () => {

  const [login_data, setLoginData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [show_pwd, setShowPwd] = useState(false)

  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.data) {
      setLoginData({ email: location?.state?.data })
    } else {
      navigate(-1)
    }
  }, [])

  const handleLoginUser = async (e) => {
    e.preventDefault()

    const login_type = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(login_data?.email) ? 1 : 2;

    const login_credential = login_type === 2 ?
      (login_data?.email.includes('+91 ') ? login_data?.email : ('+91 ' + login_data?.email)) :
      login_data?.email

    const formData = {
      ...login_data,
      email: login_credential,
      type: login_type
    }
    
    try {
      setLoading(true)
      const response = await axiosbaseurl.post('/loginUser', formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        }
      })

      if (response.data.success == false && response.data.data == "user is inactive") {
        toast.error('User is Inactive!');
        setLoading(false)
        return;
      }

      if (response.data.success == true) {
        toast.success('Login Successfull')
        secureLocalStorage.setItem("credentials", {
          user_id: response.data.data.id,
          name: response.data.data.name,
          email: response.data.data.email,
          image: response.data.data.avatar,
          blogs: response.data.data.blogs?.map((post) => post.id),
          news: response.data.data.news?.map((post) => post.id)
        })
        setTimeout(() => {
          navigate(config.appUrl)
        }, 2000)
        setLoginData({ email: '', password: '' })
      } else {
        toast.error('Invalid Email Or Password');
      }

      setLoading(false)

    } catch (error) {
      console.log(error)
      toast.error('Something Went Wrong!');
      setLoading(false)
    }
  }

  function setFormData(e) {
    setLoginData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

      <div
        className='bg-image py-4 row align-items-center mx-0'
        style={{
          marginTop: "90px",
          minHeight: '110vh',
          backgroundImage: `linear-gradient(to left, transparent 20%, #374053), url(${LoginPage2})`
        }}
      >
        <div className="col-xl-5 col-lg-6 col-md-8 ms-md-5 px-md-5">

          <div className='form-container '>
            <div className='top-form-section'>
              <img src={propuserlogo}></img>
              <h4 className='pt-5 pb-4 text-white'>Welcome to <span className='text-yellow'>Propusers </span> Family</h4>
            </div>

            <form onSubmit={handleLoginUser}>
              <div className="row">

                <div className="col-12 mb-3">

                  <div className="form-floating mb-3">
                    <input type='email' name='email'
                      onChange={setFormData}
                      value={login_data?.email}
                      className={'form-control form-field'}
                      required
                      placeholder="Email" disabled></input>

                    <label htmlFor="name">Enter Phone No/Email Id</label>
                  </div>
                </div>

                <div className="col-12">

                  <div className="form-floating mb-2">
                    <input type={`${show_pwd ? 'text' : 'password'}`} name='password'
                      onChange={setFormData}
                      value={login_data?.password}
                      className={'form-control form-field'}
                      required
                      placeholder="Email"></input>

                    <label htmlFor="name">Password</label>
                    <i className={`bi bi-eye${show_pwd ? '-slash' : ''}-fill pass-eye`} onClick={() => setShowPwd(!show_pwd)}></i>
                  </div>
                </div>
                <div className="text-end ">
                  <Link to={`${config.appUrl}forgot-password`}
                    className='text-decoration-none text-yellow'> Forgot Password ? </Link>
                </div>

              </div>

              <div className='mt-4'>
                <button className='btn-blue w-100' type='submit' >
                  {
                    loading ? "Logging In..." : "Login"
                  }
                </button>
              </div>

              <div className='mt-5 text-center'>
                <p style={{ color: "white" }}>New to Propusers ?
                  <Link to={`${config.appUrl}create-account`} className='text-decoration-none text-yellow'> Sign Up</Link></p>
              </div>
            </form>

          </div>
        </div>
      </div>

    </div>
  )
}

export default SignUPMain