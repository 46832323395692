import React, { useEffect } from 'react'

// Assets
import "../../assets/Css/Career.css"
import config from '../../config'
import { Link } from 'react-router-dom'


const Sitemap = () => {
    // console.log(process.env.REACT_APP_API_KEY)

    return (
        <div>

            <section className='pt-90'>

                <div className='px-5 my-5'>
                    <div className="row sitemap-section" >
                        <div className="common-height col-1 d-md-block d-none position-relative ">
                            <div className='bg-yellow h-100'>
                                <div className='key-icon bg-dark-blue d-md-block d-none'></div>
                            </div>
                        </div>

                        <div className="common-height col-md-11  pt-4 px-4" style={{ backgroundColor: '#faf8f4' }}>
                            <div>
                                <div className='mb-5'>
                                    <h6 className='fw-bold text-dark text-uppercase mb-4'>sitemap</h6>
                                    <h4>company Pages</h4>
                                    <div className="yellow-line"></div>
                                </div>
                                <div className='d-flex flex-wrap top-cities w-100'>

                                    <div>
                                        <Link to={`${config.appUrl}`} className='text-decoration-none paragraph2 font-saira'>
                                            Home
                                        </Link>
                                    </div>

                                    <div>
                                        <Link to={`${config.appUrl}about-us`} className='text-decoration-none paragraph2 font-saira'>
                                            About Us
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}management-team`} className='text-decoration-none paragraph2 font-saira'>
                                            Management Team
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}career`} className='text-decoration-none paragraph2 font-saira'>Career</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}contact-us`} className='text-decoration-none paragraph2 font-saira'>Contact Us</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}propreneur-experience`} className='text-decoration-none paragraph2 font-saira'>Propreneur Experience</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}office-location`} className='text-decoration-none paragraph2 font-saira'>Office Location</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}terms-and-condition`} className='text-decoration-none paragraph2 font-saira'>Term & Conditions</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}privacy-policy`} className='text-decoration-none paragraph2 font-saira'>Privacy Policy</Link>
                                    </div>

                                    <div>
                                        <Link to={`${config.appUrl}coming-soon`}
                                            state={{ data: 'Prop Makeover' }} className='text-decoration-none paragraph2 font-saira'>Prop Makeover</Link>
                                    </div>

                                    <div>
                                        <Link to={`${config.appUrl}coming-soon`}
                                            state={{ data: 'Prop Academy' }} className='text-decoration-none paragraph2 font-saira'>Prop Academy</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}coming-soon`}
                                            state={{ data: 'Prop Samadhan' }} className='text-decoration-none paragraph2 font-saira'>Prop Samadhan</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}coming-soon`}
                                            state={{ data: 'PropCafe' }} className='text-decoration-none paragraph2 font-saira'>Prop Cafe</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}coming-soon`}
                                            state={{ data: 'Prop Wallet' }} className='text-decoration-none paragraph2 font-saira'>Prop Wallet</Link>
                                    </div>

                                    <div>
                                        <Link to={`${config.appUrl}news`} className='text-decoration-none paragraph2 font-saira'>News</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}blog`} className='text-decoration-none paragraph2 font-saira'>Blogs</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}neighbourhoods`} className='text-decoration-none paragraph2 font-saira'>Neighbourhood</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}home-loan`} className='text-decoration-none paragraph2 font-saira'>Home Loan</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}rera`} className='text-decoration-none paragraph2 font-saira'>RERA</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}authorities`} className='text-decoration-none paragraph2 font-saira'>Authorities</Link>
                                    </div>

                                </div>
                            </div>

                            {/* <div className='mt-5'>
                                <div className='mb-5 '>
                                    <h4>Buy Pages</h4>
                                    <div className="yellow-line"></div>
                                </div>
                                <div className='d-flex flex-wrap top-cities w-100'>

                                    <div className='paragraph2 font-saira'>Buy</div>
                                    <div className='paragraph2 font-saira'>Property Details</div>
                                    <div className='paragraph2 font-saira'>Prop Samadhan</div>
                                    <div className='paragraph2 font-saira'>Prop Cafe</div>
                                    <div className='paragraph2 font-saira'>Prop Wallet</div>


                                </div>
                            </div> */}

                            <div className='mt-5'>
                                <div className='mb-5 '>
                                    <h4>Propreneur Pages</h4>
                                    <div className="yellow-line"></div>
                                </div>
                                <div className='d-flex flex-wrap top-cities w-100'>
                                    <div>
                                        <Link to={`${config.appUrl}proprenuers`} className='text-decoration-none paragraph2 font-saira'>Propreneurs</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}find-a-propreneur`} className='text-decoration-none paragraph2 font-saira'>Find a Propreneur</Link>
                                    </div>
                                    <div>
                                        <Link to={`${config.appUrl}join-as-a-propreneur`} className='text-decoration-none paragraph2 font-saira'>Join as a Propreneur</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

        </div>
    )
}


export default Sitemap